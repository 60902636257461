@use '../base/typography';

.o-layout {
    position: relative;
    z-index: 6;
    display: flow-root;

    /*&:not(.--page-404):not(.--page-500):not(.--page-search) {
        min-height: 100vh;
    }*/

    &:has(.c-selects) .c-page-title__text-block {
        padding-bottom: calc(var(--selects-height) + 25px);
    }

    .c-button-activity,
    .c-button-directory {
        pointer-events: all;

        &:not(.--mobile) {
            @extend .c-button;
            @extend .c-button--dark-blue-to-lime;
            @extend .c-button--svg-on-the-right;

            &::before {
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
            }

            @media screen and (max-width: 600px) { display: none; }
        }

        &.--mobile {
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: var(--activity-button-height);
            background-color: rgb(var(--rgb-dark-blue));
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);

            span {
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 1.6em;
                color: rgb(var(--rgb-white));
                box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-white), 0.2);
                transition: box-shadow 300ms var(--out-cubic);
            }

            &:hover span {
                box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-white));
            }

            @media screen and (min-width: 601px) { display: none; }
        }
    }

    .c-button-calendar {
        margin-bottom: 15px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        pointer-events: all;

        @media screen and (min-width: 1101px) { display: none; }
        @media screen and (max-width: 600px) { margin-bottom: 0; }
    }

    &.--page-maintenance .c-button-share {
        display: none;
    }
}