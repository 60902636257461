.c-button-share {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(var(--rgb-light-blue));
    transition: background-color 300ms;

    &:hover {
        background-color: rgb(var(--rgb-dark-blue));
    }

    svg {
        width: 18px;
        height: 18px;
        fill: rgb(var(--rgb-dark-blue));
        transition: fill 300ms;
    }

    &:hover svg {
        fill: rgb(var(--rgb-white));
    }
}
