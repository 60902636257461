.c-header {
    position: absolute;
    top: var(--alert-height);
    right: 0;
    left: 0;
    z-index: 25;
    transition: top 500ms var(--in-out-cubic);

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: var(--header-height);
        padding-right: var(--x-40);
        padding-left: var(--x-40);
    }

    &__left {
        position: relative;
        z-index: 5;
    }

    &__logo-container {
        display: block;
        width: var(--header-logo-width);
        height: 63px;

        @media screen and (max-width: 600px) {
            width: 173px;
            height: 41px;
        }
        @media screen and (max-width: 360px) {
            width: 150px;
            height: 36px;
        }
    }

    &__logo {
        width: 100%;
        height: 100%;
    }

    &.--home .c-breadcrumb {
        display: none;
    }

    &__right {
        position: relative;
        z-index: 5;
        width: var(--header-right-width);

        @media screen and (max-width: 1024px) { width: initial; }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > * {
            flex-shrink: 0;
        }

        .c-button-events {
            @media screen and (max-width: 1024px) { margin-right: 10px; }
            @media screen and (max-width: 500px) { display: none; }
        }

        .c-button-search {
            @media screen and (max-width: 1024px) { margin-right: 10px; }
        }
    }
}