.c-filter {
    --navigation-width: 80px;
    @media screen and (max-width: 600px) { --navigation-width: 100%; }
}

.c-filter {
    overflow: hidden;
    width: 100%;

    &__title {
        display: block;
        width: 100%;
        margin-bottom: 20px;

        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.4em;
        text-transform: uppercase;
        text-align: center;
    }

    &__swiper {
        position: relative;
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid rgba(var(--rgb-dark-blue), 0.3);

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 2;
            pointer-events: none;
            transition: opacity 200ms;
        }

        &::before {
            left: -35px;
            width: 80px;
            background: linear-gradient(90deg, rgba(var(--rgb-light-blue), 1) 50%, rgba(255, 255, 255, 0) 75%);
        }

        &.--no-prev::before {
            opacity: 0;
        }

        &::after {
            right: 0;
            width: calc(var(--navigation-width) + 80px);
            background: linear-gradient(270deg, rgba(var(--rgb-light-blue),1) 45%, rgba(255,255,255,0) 80%);

            @media screen and (max-width: 600px) {
                right: -35px;
                width: 80px;
            }
        }

        &.--no-next::after {
            opacity: 0;
        }
    }

    .swiper {
        width: calc(100% - var(--navigation-width));
        margin-left: 0;

        @media screen and (max-width: 600px) { width: 100%; }
    }

    .swiper-slide {
        width: fit-content;
    }

    &__button {
        display: flex;
        align-items: center;
        padding-top: 8px;
        padding-right: 18px;
        padding-bottom: 8px;
        padding-left: 18px;
        border-radius: 46px;
        background-color: rgb(var(--rgb-light-blue));
        transition: background-color 300ms;

        &.--active {
            background-color: rgb(var(--rgb-dark-blue));
        }

        &:hover {
            background-color: rgb(var(--rgb-white));
        }

        svg {
            width: 22px;
            height: 22px;
            margin-right: 8px;
            fill: none;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 8px;
        }

        &.--active svg {
            stroke: rgb(var(--rgb-white));
        }

        &:hover svg {
            stroke: rgb(var(--rgb-dark-blue));
        }

        span {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.8em;
            color: rgba(var(--rgb-dark-blue), 0.8);
        }

        &.--active span {
            color: rgb(var(--rgb-white));
        }

        &:hover span {
            color: rgb(var(--rgb-dark-blue));
        }
    }

    .swiper-slide:first-child &__button svg {
        stroke-width: 1.5px;
    }

    &__swiper-navigation {
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 5;
        translate: 0 -50%;
        width: var(--navigation-width);
        height: 50px;
        transition: opacity 300ms, visibility 0ms 0ms;

        &.--hidden {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: opacity 300ms, visibility 300ms 0ms;
        }
    }

    &__swiper-navigation-container {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 39px;
        background-color: rgb(var(--rgb-white));

        @media screen and (max-width: 600px) {
            padding: 0;
            border-radius: 0;
            background-color: transparent;
        }
    }

    &__swiper-arrow-prev,
    &__swiper-arrow-next {
        width: 14px;
        height: 14px;
        transition: opacity 300ms, visibility 0ms 0ms;

        @media screen and (max-width: 600px) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: rgb(var(--rgb-white));
        }

        svg {
            width: 100%;
            height: 100%;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;

            @media screen and (max-width: 600px) {
                width: 14px;
                height: 14px;
            }
        }
    }

    &__swiper-arrow-prev svg {
        rotate: 180deg;
    }

    .swiper-button-disabled {
        opacity: 0.6;
        pointer-events: none;

        @media screen and (max-width: 600px) { opacity: 0; }
    }

    .swiper-button-lock {
        display: block!important;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 300ms, visibility 300ms 0ms;
    }
}