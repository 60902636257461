.c-overlay-menu {
    --overlay-menu-width: 510px;
    @media screen and (max-width: 600px) { --overlay-menu-width: 100%; }
}

.c-overlay-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    isolation: isolate;
    visibility: hidden;
    pointer-events: none;
    transition: visibility 0ms 600ms;

    &__container {
        position: absolute;
        right: calc(0px - var(--overlay-menu-width));
        bottom: 0;
        width: var(--overlay-menu-width);
        height: calc(var(--vh, 1vh) * 100);
        background-color: rgb(var(--rgb-darker-blue));
        border-top-left-radius: 100px;
        transition: right 600ms var(--in-out-cubic);

        @media screen and (max-width: 600px) { border-radius: 0; }
    }

    &__sections {
        position: relative;
        height: 100%;
    }

    &__close-overlay-button {
        position: absolute;
        top: 45px;
        right: 30px;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-white));
        transition: background-color 300ms;

        @media screen and (max-width: 600px) { top: 22.5px; }

        &:hover {
            background-color: rgb(var(--rgb-dark-blue));
        }

        svg {
            width: 16px;
            height: 16px;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;
            transition: stroke 300ms;
        }

        &:hover svg {
            stroke: rgb(var(--rgb-white));
        }
    }

    &__section {
        position: relative;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    &__section:not([data-section="primary"]) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        padding-top: max(var(--y-100), 90px);
    }

    // Section primaire
    &__section[data-section="primary"] {
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: max(var(--y-160), 80px);

        &::before {
            content: "";
            position: absolute;
            top: max(var(--y-160), 80px);
            right: 0;
            left: 0;
            z-index: 5;
            height: 30px;
            background: linear-gradient(180deg, rgba(var(--rgb-darker-blue),1) 5%, rgba(var(--rgb-darker-blue),0) 100%);
        }
    }

    &__top-section {
        position: relative;
        translate: 30px 0;
        z-index: 5;
        padding-right: 50px;
        padding-left: 50px;
        opacity: 0;
        transition: opacity 600ms linear 0ms, translate 600ms 0ms;

        @media screen and (max-width: 600px) {
            padding-right: 40px;
            padding-left: 40px;
        }

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: -50px;
            left: 0;
            height: 50px;
            background: linear-gradient(180deg, rgba(var(--rgb-darker-blue),1) 5%, rgba(var(--rgb-darker-blue),0) 100%);
        }
    }

    &__section-content {
        overflow-y: auto;
        overflow-x: hidden;
        translate: 30px 0;
        flex: 1;
        padding-right: 50px;
        padding-left: 50px;
        opacity: 0;
        transition: opacity 600ms linear 0ms, translate 600ms 0ms;

        @media screen and (max-width: 600px) {
            padding-right: 40px;
            padding-left: 40px;
        }

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--rgb-white), 0.8);
            border-radius: 10px;
        }
    }

    &__section:not([data-section="primary"]) &__section-content {
        padding-bottom: 45px;
    }

    &__section[data-section="primary"] &__section-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }

    &__primary-list,
    &__primary-list-item,
    &__primary-button {
        width: 100%;
    }

    &__primary-list {
        margin-bottom: 30px;
    }

    &__primary-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(var(--rgb-white), 0.3);

        span:first-child {
            margin-right: 10px;

            font-size: var(--fz-45);
            line-height: 1.3em;
            color: rgb(var(--rgb-white));

            @media screen and (max-height: 700px) { font-size: var(--fz-35); }
        }

        span:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid rgba(var(--rgb-white), 0.3);
            transition: background-color 300ms;
        }

        &:hover span:last-child {
            background-color: rgb(var(--rgb-white));
        }

        svg {
            width: 16px;
            height: 16px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
            transition: stroke 300ms;
        }

        &:hover svg{
            stroke: rgb(var(--rgb-dark-blue));
        }
    }

    &__primary-bottom-content {
        padding-bottom: 45px;
    }

    &__primary-bottom-list {
        columns: 2;

        @media screen and (max-width: 600px) { columns: 1; }
    }

    &__primary-bottom-list-item {
        margin-right: 24px;
        margin-bottom: 12px;

        @media screen and (max-width: 600px) { margin-right: 0; }
    }

    &__primary-bottom-link {
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        span {
            font-size: var(--fz-17);
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
        }
    }

    &__social-medias-list {
        display: flex;
        align-items: center;
        margin-top: 40px;
    }

    &__social-medias-list-item:not(:last-child) {
        margin-right: 25px;
    }

    &__social-medias-link {
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        svg {
            width: 22px;
            height: 22px;
            fill: rgb(var(--rgb-white));
        }
    }

    &__return-button {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        svg {
            rotate: 180deg;
            width: 14px;
            height: 14px;
            margin-right: 18px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
        }

        span {
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
        }
    }

    &__tiny-title {
        font-size: max(var(--fz-24), 2rem);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));

        @media screen and (max-height: 700px) { font-size: max(var(--fz-20), 1.9rem); }
    }

    &__list-box {
        padding-top: 40px;
        padding-right: 50px;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(var(--rgb-white), 0.3);
    }

    &__title {
        margin-bottom: 30px;

        font-size: max(var(--fz-30), 2.6rem);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));

        @media screen and (max-height: 700px) { font-size: max(var(--fz-25), 2rem); }
    }

    &__secondary-list-item:not(:last-child) {
        margin-bottom: 10px;
    }

    &__secondary-link {
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        span {
            font-size: var(--fz-17);
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
        }
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: rgba(var(--rgb-white), 0.3);
        opacity: 0;
        transition: opacity 300ms;
    }
}

/*
|--------------------------------------------------------------------------
| SHOW OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-menu {
    body {
        --alert-height: 0px;
    }

    .c-overlay-menu {
        pointer-events: all;
        visibility: visible;
        transition: visibility 0ms 0ms;

        &__container {
            right: 0;
            pointer-events: all;
        }

        &__section-content {
            transition: opacity 300ms linear 0ms, translate 600ms 0ms;
        }

        &__section[data-section="primary"] .c-overlay-menu__section-content {
            translate: -30px 0;
        }

        &__background {
            opacity: 1;
        }
    }
}

.show-overlay-menu.opening-overlay-menu,
.show-overlay-menu.closing-overlay-menu {
    .c-overlay-menu {
        &__section[data-section="primary"] .c-overlay-menu__section-content {
            translate: 30px 0;
            transition: opacity 600ms linear 300ms, translate 600ms 300ms;
        }
    }
}


/*
|--------------------------------------------------------------------------
| TRIGGERED SECTION
|--------------------------------------------------------------------------
*/
@mixin sectionMenu($section) {
    .show-overlay-menu:not(.closing-overlay-menu)[data-section="#{$section}"] {
        .c-overlay-menu [data-section="#{$section}"] {
            pointer-events: all;

            .c-overlay-menu__top-section,
            .c-overlay-menu__section-content {
                opacity: 1;
                translate: 0;
                transition: opacity 300ms linear 300ms, translate 600ms 300ms;
            }
        }
    }
}

// Section primaire
@include sectionMenu(primary);

// Sections secondaires
@include sectionMenu(art-et-culture);
@include sectionMenu(patrimoine);
@include sectionMenu(ressources);


/*
|--------------------------------------------------------------------------
| CLOSING OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-menu.closing-overlay-menu {
    body {
        --alert-height: 0px;
    }

    .c-overlay-menu {
        &__container {
            right: calc(0px - var(--overlay-menu-width));
        }

        &__background {
            opacity: 0;
        }
    }
}