/*
|--------------------------------------------------------------------------
| Les Placeholders
|--------------------------------------------------------------------------
|
| Les placeholders permettent de réutiliser certaines propriétés CSS.
|
*/

// Fix border radius safari
%border-radius {
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

%scrollfire-properties {
    translate: 0 50px;
    opacity: 0;
    transition-property: opacity, translate;
    transition-duration: 800ms;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
}
