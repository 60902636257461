@use "../../base/typography";

.c-overlay-share {
    --button-x-spacing: 20px;
    @media screen and (max-width: 600px) { --button-x-spacing: 10px; }
}

.c-overlay-share {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 100;

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 520px;
        transform: translateX(-50%);
        padding: 85px;
        border-radius: 50px;
        background-color: rgb(var(--rgb-white));
        opacity: 0;
        pointer-events: none;
        will-change: transform;
        transition: opacity 800ms var(--in-out-quart), transform 800ms var(--in-out-quart);

        text-align: center;

        @media screen and (max-width: 1200px) { width: 490px; }

        @media screen and (max-width: 1024px) {
            width: 400px;
            padding: 50px;
        }

        @media screen and (max-width: 600px) {
            right: 20px;
            left: 20px;
            transform: none;
            width: auto;
            padding: 50px 20px;
            border-radius: 25px;
        }
    }

    &__title {
        @extend .c-medium-title;

        margin-bottom: 20px;

        color: rgb(var(dark-blue));
    }

    &__button-close {
        position: absolute;
        top: 30px;
        right: 30px;
        display: flex;
        align-items: center;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        svg {
            width: 15px;
            height: 15px;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;
        }
    }

    &__list {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 16px calc(var(--button-x-spacing) / -2) 0;
    }

    &__link {
        position: relative;
        display: block;
        width: 45px;
        height: 45px;
        margin: 0 calc(var(--button-x-spacing) / 2);
        border-radius: 50%;
        background-color: rgb(var(--rgb-light-blue));
        cursor: pointer;
        transition: background-color 300ms;

        &:hover {
            background-color: rgb(var(--rgb-dark-blue));
        }
    }

    &__svg {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        width: 22px;
        height: 22px;
        fill: rgb(var(--rgb-dark-blue));
        transition: fill 300ms;
    }

    &__link:hover &__svg {
        fill: rgb(var(--rgb-white));
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-dark-blue), 0);
        transition: background-color 300ms;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| SHOW OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-share {
    .c-overlay-share {
        pointer-events: all;
        visibility: visible;

        &__container {
            opacity: 1;
            pointer-events: all;
            transform: translate(-50%, -50%);

            @media screen and (max-width: 600px) {
                transform: translateY(-50%);
            }
        }

        &__background {
            background-color: rgba(var(--rgb-dark-blue), 0.3);
        }
    }
}


/*
|--------------------------------------------------------------------------
| CLOSING OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-share.closing-overlay-share {
    .c-overlay-share {
        &__container {
            opacity: 0;
            transform: translateX(-50%);

            @media screen and (max-width: 600px) {
                transform: none;
            }
        }

        &__background {
            background-color: rgba(var(--rgb-dark-blue), 0);
        }
    }
}
