@use '../../base/typography.scss';

.c-result-card {
    &__container {
        padding-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__category {
        display: inline-block;
        margin-bottom: 8px;

        font-size: 1.3rem;
        font-weight: 600;
        line-height: 1.6em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__title {
        margin-bottom: 20px;

        font-size: var(--fz-30);
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));

        mark {
            font-weight: 700;
            color: rgb(var(--rgb-dark-blue));
        }
    }

    &__text {
        @extend .c-paragraph;

        margin-bottom: var(--y-40);

        color: rgb(var(--rgb-dark-blue));

        mark {
            font-weight: 700;
            color: rgb(var(--rgb-dark-blue));
        }
    }

    &__false-link {
        display: block;

        span {
            padding-bottom: 4px;
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-dark-blue), 0.4);
            transition: box-shadow 300ms var(--out-cubic);

            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.6em;
            color: rgb(var(--rgb-dark-blue));
        }

    }

    &:hover &__false-link span {
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-dark-blue));
    }
}

.c-search-results__list-item:first-child {
    .c-result-card {
        &__container {
            padding-top: 0;
        }
    }
}