.c-publications {
    &__list-item:first-child .c-publication-card__container {
        padding-top: 0;
    }

    &__no-items {
        position: relative;
        font-size: var(--fz-30);
        font-weight: 500;
        line-height: 1.3em;
        z-index: 10000;
        margin: auto;
    }
}