@use "../base/typography";

.c-publications-component {
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__top-section {
        display: flex;
        justify-content: center;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__top-section-text {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.4em;
        letter-spacing: 0.1em;
        color: rgb(var(--rgb-dark-blue));
        text-transform: uppercase;
    }

    &__button {
        @extend .c-button;
        @extend .c-button--white-to-lime;
        @extend .c-button--svg-on-the-right;

        align-self: center;
        margin-top: 60px;

        @media screen and (max-width: 600px) { margin-top: 30px; }
    }
}