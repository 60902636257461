.o-picture {
    position: relative;
    
    &:not(&--contain) img {
        object-fit: cover;
    }

    &.--contain img {
        object-fit: contain;
    }

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}