@use "sass:math";
@use "../abstract/placeholders";

// LES TITRES ----------
// Très Gros titre (h1)
.c-huge-title {
    font-size: var(--fz-100);
    line-height: 1.3em;
}

// Gros titre (h2)
.c-large-title {
    font-size: var(--fz-40);
    font-weight: 600;
    line-height: 1.3em;
}

// Moyen titre (h3)
.c-medium-title {
    font-size: var(--fz-30);
    font-weight: 500;
    line-height: 1.3em;
}

// Petit titre (h4)
.c-small-title {
    font-size: var(--fz-24);
    font-weight: 500;
    line-height: 1.3em;
}


// LES TEXTES ----------
// Le paragraphe
.c-paragraph {
    font-size: min(var(--fz-17), 1.7rem);
    line-height: var(--paragraph-line-height);
}

// L'introduction
.c-introduction  {
    font-size: var(--fz-25);
    line-height: 1.6em;
}

// La note
.c-note {
    font-family: var(--ff);
    font-size: var(--fz-14);
    line-height: 1.6em;
    color: rgba(var(--rgb-dark-blue), 0.8);
    white-space: normal;
}

// La citation
.c-quote {
    padding-left: 40px;
    border-left: 10px solid rgb(var(--rgb-lime));

    @media screen and (max-width: 1024px) {
        padding-left: 25px;
        border-width: 8px;
    }
    @media screen and (max-width: 768px)  {
        padding-left: 20px;
        border-width: 6px;
    }
    @media screen and (max-width: 500px)  {
        padding-left: 15px;
        border-width: 4px;
    }

    &, p {
        margin: 0;

        font-family: var(--ff);
        font-size: var(--fz-22);
        font-weight: 500;
        line-height: 1.6em;
    }
}


// LES LIENS ----------
// Le lien surligné
.c-underlined-link {
    @extend .c-paragraph;

    font-weight: 600;
    color: rgb(var(--rgb-dark-blue));
    text-decoration: none;

    span:first-child {
        padding-bottom: 2px;
        box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-dark-blue), 0.4);
        transition: box-shadow 300ms var(--out-cubic);
    }

    &:hover span:first-child {
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-dark-blue));
    }

    // Icones liens externes et téléchargement
    svg {
        margin-left: math.div(2, 17) + em;
        width: math.div(12, 17) + em;
        height: math.div(12, 17) + em;
        stroke: rgb(var(--rgb-dark-blue));
        stroke-width: 3px;
    }
}


// LES BOUTONS ----------
// Le bouton plein et vide
.c-button {
    @extend %border-radius;

    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    padding: 6px 6px 6px 37px;
    border-radius: 100px;
    transition: background-color 300ms;
    isolation: isolate;

    &::before,
    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        border-radius: 100px;
    }

    &::before {
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        border: 1px solid var(--button-border-color);
        background-color: var(--button-background-color);
    }

    &::after {
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        background-color: var(--button-background-color-hover);
        translate: 0 100%;
        transition: translate 300ms var(--out-cubic);
    }

    &--svg-on-the-left {
        padding: 6px 37px 6px 6px;
    }

    &--svg-on-the-left span:last-child,
    &:not(.c-button--svg-on-the-left) span:first-child {
        margin-bottom: -3px;
        transition: color 300ms;

        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.3em;
        color: var(--button-color);
        text-align: left;
    }

    &--svg-on-the-left span:first-child,
    &:not(.c-button--svg-on-the-left) span:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--button-circle-background-color);
        border: 1px solid var(--button-circle-border-color);
        transition: background-color 300ms, border-color 300ms;
    }

    svg {
        display: inline-block;
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        stroke: var(--button-svg-color);
        stroke-width: 2px;
        transition: stroke 300ms;
    }

    &--svg-on-the-left {
        span:last-child {
            margin-left: var(--button-svg-spacing, 20px);
        }
    }

    &--svg-on-the-right {
        span:last-child {
            margin-left: var(--button-svg-spacing, 20px);
        }
    }

    &--white-to-lime {
        --button-color: rgb(var(--rgb-dark-blue));
        --button-background-color: rgb(var(--rgb-white));
        --button-background-color-hover: rgb(var(--rgb-lime));
        --button-border-color: rgba(var(--rgb-dark-blue), 0.3);
        transition: all 0.3s;

        span:last-child {
            --button-circle-background-color: rgb(var(--rgb-light-blue));
            --button-circle-border-color: rgb(var(--rgb-light-blue));
        }

        svg {
            --button-svg-color: rgb(var(--rgb-dark-blue));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));

            &::after {
                translate: 0;
            }

            span:last-child {
                --button-circle-background-color: transparent;
                --button-circle-border-color: rgb(var(--rgb-white));
            }

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }

        &:disabled {
            --button-color: rgb(var(--rgb-dark-gray));
            --button-background-color: rgb(var(--rgb-white));
            --button-background-color-hover: rgb(var(--rgb-white));
            --button-border-color: rgba(var(--rgb-dark-gray));
            transition: all 0.3s;

            span:last-child {
                --button-circle-background-color: rgba(var(--rgb-dark-gray), 0.3);
                --button-circle-border-color: rgb(var(--rgb-light-gray));
            }

            svg {
                --button-svg-color: rgb(var(--rgb-dark-gray));
            }

            &:hover {
                --button-color: rgb(var(--rgb-dark-gray));
                cursor: not-allowed;

                &::before {
                    border: 1px solid rgba(var(--rgb-dark-gray)) !important;
                }

                &::after {
                    display: none;
                }

                span:last-child {
                    --button-circle-background-color: rgba(var(--rgb-dark-gray), 0.3);
                    --button-circle-border-color: rgb(var(--rgb-light-gray));
                }

                svg {
                    --button-svg-color: rgb(var(--rgb-dark-gray));
                }
            }
        }
    }

    &--dark-blue-to-lime {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-dark-blue));
        --button-background-color-hover: rgb(var(--rgb-lime));
        --button-border-color: rgb(var(--rgb-dark-blue));

        span:last-child,
        span:first-child {
            --button-circle-background-color: rgb(var(--rgb-darker-blue));
            --button-circle-border-color: rgb(var(--rgb-darker-blue));
        }

        svg {
            --button-svg-color: rgb(var(--rgb-white));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));

            &::after {
                translate: 0;
            }

            span:last-child,
            span:first-child {
                --button-circle-background-color: transparent;
                --button-circle-border-color: rgb(var(--rgb-white));
            }

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }
    }

}


// LES LISTES ----------
// Liste désordonnée et ordonnée
.c-list {
    li {
        @extend .c-paragraph;

        p {
            margin: 0;
        }

        &:not(:first-child) {
            margin-top: 12px;
        }

        ol,
        ul {
            width: 100%;
            margin-top: 12px;
            margin-bottom: 0;

            li {
                &:not(:first-child) {
                    margin-top: 10px;
                }
            }
        }
    }

    &--unordered {
        --unordered-list-padding: 18px;
        @media screen and (max-width: 500px) { --unordered-list-padding: 15px; }

        & > li {
            width: calc(100% - var(--unordered-list-padding));
            position: relative;
            left: var(--unordered-list-padding);

            &::before {
                content: "";
                width: 6px;
                height: 6px;
                position: absolute;
                top: calc(var(--paragraph-line-height) / 2);
                transform: translate(calc(0px - var(--unordered-list-padding)), -50%);
                background-color: rgb(var(--rgb-dark-blue));
                border: 1px solid rgb(var(--rgb-dark-blue));
                border-radius: 50%;
                box-sizing: border-box;
            }

            & > ul li {
                &::before {
                    background-color: transparent;
                }

                & > ul li::before {
                    background-color: rgb(var(--rgb-black));
                }
            }
        }
    }

    &--ordered {
        --ordered-list-padding: 50px;
        @media screen and (max-width: 768px) { --ordered-list-padding: 25px; }
        @media screen and (max-width: 500px) { --ordered-list-padding: 20px; }
        list-style: none;
        counter-reset: li;

        & > li {
            width: calc(100% - var(--ordered-list-padding));
            position: relative;
            left: var(--ordered-list-padding);

            &::before {
                content: counter(li) ".";
                counter-increment: li;
                display: inline-block;
                position: absolute;
                transform: translateX(calc(0px - var(--ordered-list-padding)));

                font-weight: 600;
            }

            ol > li {
                &::before {
                    content: counter(li, lower-alpha) ".";
                }

                ol > li {
                    @media screen and (min-width: 769px) { padding-left: 40px; }

                    &::before {
                        content: counter(li, lower-roman) ".";
                    }
                }
            }
        }
    }
}


// LES TABLEAUX ET ZONES ----------
// Le tableau
.c-table {
    --border-radius: 8px;
    overflow-x: auto;

    table {
        overflow-x: hidden;
        border-collapse: collapse;
        margin-right: 0 !important;

        tr {
            // Applique les styles pour toutes les cellules
            th,
            td {
                @extend .c-paragraph;

                padding: 20px 35px;
                border: 1px solid rgba(var(--rgb-dark-blue), 0.3);

                & > :first-child {
                    margin-top: 0;
                }

                & > *:last-child {
                    margin-bottom: 0 !important;
                }

                @media screen and (max-width: 1024px) { padding: 20px 30px; }
                @media screen and (max-width: 768px)  { padding: 18px 25px; }
                @media screen and (max-width: 500px)  { padding: 16px 20px; }
            }

            // Applique les styles pour les cellules d'en-tête
            th,
            td.table-header {
                background-color: rgb(var(--rgb-dark-blue));
                border-top-color: rgb(var(--rgb-dark-blue));
                border-right-color: rgba(var(--rgb-white), 0.3);
                border-bottom-color: rgb(var(--rgb-dark-blue));

                font-weight: 600;
                color: rgb(var(--rgb-white));
                text-align: left;

                &:first-child {
                    border-left-color: rgb(var(--rgb-dark-blue));
                }

                &:last-child {
                    border-right-color: rgb(var(--rgb-dark-blue));
                }
            }

            // Applique la couleur de background pour les cellules d'en-tête secondaire
            td.table-secondary-header {
                background-color: rgb(var(--rgb-light-blue));
            }
        }
    }

    // Ajustement des styles du tableaux avec des bordures arrondies
    &--rounded-borders {

        table {
            border-collapse: separate;
            border-radius: var(--border-radius);

            // Le border-radius de la première cellule dans le thead
            thead tr:first-child th:first-child {
                border-top-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans le thead
            thead tr:first-child th:last-child {
                border-top-right-radius: var(--border-radius);
            }

            // Le border-radius de la première cellule dans la première rangée du tbody
            tbody:first-child tr:first-child th:first-child,
            tbody:first-child tr:first-child td:first-child {
                border-top-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans la première rangée du tbody
            tbody:first-child tr:first-child th:last-child,
            tbody:first-child tr:first-child td:last-child {
                border-top-right-radius: var(--border-radius);
            }

            // Le border-radius de la première cellule dans la dernière rangée du tbody
            tr:last-child th:first-child:not(thead tr:last-child th:first-child),
            tr:last-child td:first-child:not(thead tr:last-child td:first-child) {
                border-bottom-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans la dernière rangée du tbody
            tr:last-child th:last-child:not(thead tr:last-child th:last-child),
            tr:last-child td:last-child:not(thead tr:last-child td:last-child) {
                border-bottom-right-radius: var(--border-radius);
            }

            tr {
                // Ajustement des bordures à cause du border-collapse separate (Bordures horizontales en double)
                th,
                td {
                    &:not(:first-child) {
                        border-left: none;
                    }
                }
            }

            // Ajustement des bordures à cause du border-collapse separate (Bordures verticales en double)
            tr:not(:last-child) th,
            tr:not(:last-child) td {
                border-bottom: none;
            }
        }
    }
}


// Zone d'emphase
.c-emphasis {
    padding: var(--y-55) var(--x-55);
    border-radius: 50px;
    background-color: rgb(var(--rgb-light-blue));

    @media screen and (max-width: 768px) { border-radius: 40px; }
    @media screen and (max-width: 600px) { border-radius: 25px; }

    & > :first-child {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0
    }
}
