/*
|--------------------------------------------------------------------------
| Les variables de couleur
|--------------------------------------------------------------------------
|
| Déclaration des variables de couleurs du projet
|
*/

:root {
    // #000000 - Noir
    --rgb-black: 0, 0, 0;
    // #646464 - Gris foncé
    --rgb-dark-gray: 100, 100, 100;
    // #969696 - Gris
    --rgb-gray: 150, 150, 150;
    // #c8c8c8 - Gris pâle
    --rgb-light-gray: 200, 200, 200;
    // #ffffff - Blanc
    --rgb-white: 255, 255, 255;
    // #E8EDF0 - Bleu pâle
    --rgb-light-blue: 232, 237, 240;
    // #296587 - Bleu foncé
    --rgb-dark-blue: 41, 101, 135;
    // #1A465F - Bleu plus foncé
    --rgb-darker-blue: 26, 70, 95;
    // #91BD51 - Lime
    --rgb-lime: 145, 189, 81;
    // #E0192C - Rouge
    --rgb-red: 224, 25, 44;
}
