.c-large-documents {
    &__ending {
        position: relative;
        overflow: hidden;
        min-height: var(--rubric-header-height);
        border-top: 1px solid rgba(var(--rgb-dark-blue), 0.3);
        border-right: 1px solid rgba(var(--rgb-dark-blue), 0.3);
        border-bottom: 1px solid transparent;
        border-left: 1px solid rgba(var(--rgb-dark-blue), 0.3);
        border-top-left-radius: var(--rubric-border-radius);
        border-top-right-radius: var(--rubric-border-radius);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(var(--rubric-header-height) - 34px);
            background-color: rgb(var(--rgb-white));
        }
    }
}