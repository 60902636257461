@use '../../base/typography';

.c-overlay-search {
    --overlay-search-height: 100px;
}

.c-overlay-search {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    isolation: isolate;
    visibility: hidden;
    pointer-events: none;
    transition: visibility 0ms 600ms;

    &__container {
        position: absolute;
        top: calc(0px - var(--overlay-search-height));
        right: 40px;
        left: 40px;
        height: var(--overlay-search-height);
        background-color: rgb(var(--rgb-white));
        border-radius: 25px;
        transition: top 600ms var(--in-out-cubic);

        @media screen and (max-width: 700px) {
            right: 20px;
            left: 20px;
        }
        @media screen and (max-width: 500px) {
            right: 0;
            left: 0;
            border-radius: 0;
        }
    }

    .c-search-field {
        position: relative;
        width: 100%;
        height: 100px;

        .c-search-field__input-container {
            width: 100%;
            height: 100%;
        }

        .c-search-field__input {
            width: 100%;
            height: 100%;
            padding-right: 280px;
            padding-left: 25px;
            border: none;
            border-radius: 25px;
            box-shadow: 0 0 0 100px rgb(var(--rgb-white)) inset;
            background-color: rgb(var(--rgb-white));

            font-family: var(--ff);
            font-size: 2.4rem;
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-dark-blue));

            &::-webkit-input-placeholder { color: rgba(var(--rgb-dark-blue), 0.5); }
            &::-moz-placeholder { color: rgba(var(--rgb-dark-blue), 0.5); }
            &:-ms-input-placeholder { color: rgba(var(--rgb-dark-blue), 0.5); }
            &:-moz-placeholder { color: rgba(var(--rgb-dark-blue), 0.5); }
            &::placeholder { color: rgba(var(--rgb-dark-blue), 0.5); }

            @media screen and (max-width: 700px) {
                padding-right: 110px;
                font-size: 2rem;
            }
            @media screen and (max-width: 500px) { font-size: 1.8rem; }
            @media screen and (max-width: 400px) { font-size: 1.7rem; }
        }

        .c-search-field__search-button {
            @extend .c-button;
            @extend .c-button--white-to-lime;
            @extend .c-button--svg-on-the-right;

            position: absolute;
            top: 50%;
            right: 25px;
            translate: 0 -50%;

            @media screen and (max-width: 700px) { padding-left: 6px; }

            span:first-child {
                @media screen and (max-width: 700px) { display: none; }
            }

            span:last-child {
                @media screen and (max-width: 700px) {
                    width: 40px;
                    height: 40px;
                    margin-left: 0;
                }
            }
        }

        .c-search-field__x {
            position: absolute;
            top: 50%;
            right: 240px;
            translate: 0 -50%;
            display: block;
            width: 16px;
            height: 16px;
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;

            @media screen and (max-width: 700px) { right: 85px; }

            svg {
                width: 100%;
                height: 100%;
                stroke: rgb(var(--rgb-dark-blue));
                stroke-width: 2px;
                transition: opacity 300ms;
            }
        }

        &[data-inputvalue]:not([data-inputvalue=""]) .c-search-field__x {
            opacity: 1;
            pointer-events: all;
            transition: opacity 300ms;

            &:hover svg {
                opacity: 0.6;
            }
        }
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: rgba(var(--rgb-dark-blue), 0.3);
        opacity: 0;
        transition: opacity 300ms;
    }
}

/*
|--------------------------------------------------------------------------
| SHOW OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-search {
    body {
        --alert-height: 0px;
    }

    .c-overlay-search {
        pointer-events: all;
        visibility: visible;
        transition: visibility 0ms 0ms;

        &__container {
            top: 25px;
            pointer-events: all;

            @media screen and (max-width: 500px) { top: 0; }
        }

        &__background {
            opacity: 1;
        }
    }
}


/*
|--------------------------------------------------------------------------
| CLOSING OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-search.closing-overlay-search {
    body {
        --alert-height: 0px;
    }

    .c-overlay-search {
        &__container {
            top: calc(0px - var(--overlay-search-height));
        }

        &__background {
            opacity: 0;
        }
    }
}