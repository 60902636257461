/*
|--------------------------------------------------------------------------
| La variable des font-sizes
|--------------------------------------------------------------------------
|
| Mapping des font-sizes à l'intérieur de la variable $font-sizes. Les
| font-sizes sont converties en variable CSS dans le mixin fontSizes étant
| le fichier abstract/mixins.scss qui celui-ci est appelé dans le fichier
| base/root.scss.
|
| Il y a deux façons de créer les font-sizes: en utilisant le value & min
| & max ou en donnant manuellement des media query. 
|
| Pour la première option, Il faut donner un chiffre sans unité de mesure
| pour la valeur de base (value). Il faut donner un chiffre avec une unité de
| mesure en pixel ou en pourcentage pour la valeur minimale (min) et
| maximale (max).
| 
| Pour la deuxième option, Il faut donner une valeur de base (base) ainsi
| que les media query sans le prefix @media ((min-width: 769px)).
|
| $font-sizes: (
|     "70": (
|         "value": 70,
|         "min": 40px,
|         "max": 110%,
|     ),
|     "17": (
|         "base": 16,
|         "(min-width: 769px)": 17,
|         "(min-width: 1441px)": 18
|     ),
| );
|
*/

$font-sizes: (
    "130": (
        "value": 130,
        "min": 49px,
        "max": 110%,
    ),
    "129": (
        "value": 129,
        "min": 48px,
        "max": 110%,
    ),
    "128": (
        "value": 128,
        "min": 48px,
        "max": 110%,
    ),
    "127": (
        "value": 127,
        "min": 48px,
        "max": 110%,
    ),
    "126": (
        "value": 126,
        "min": 48px,
        "max": 110%,
    ),
    "125": (
        "value": 125,
        "min": 48px,
        "max": 110%,
    ),
    "124": (
        "value": 124,
        "min": 48px,
        "max": 110%,
    ),
    "123": (
        "value": 123,
        "min": 48px,
        "max": 110%,
    ),
    "122": (
        "value": 122,
        "min": 48px,
        "max": 110%,
    ),
    "121": (
        "value": 121,
        "min": 48px,
        "max": 110%,
    ),
    "120": (
        "value": 120,
        "min": 48px,
        "max": 110%,
    ),
    "119": (
        "value": 119,
        "min": 47px,
        "max": 110%,
    ),
    "118": (
        "value": 118,
        "min": 47px,
        "max": 110%,
    ),
    "117": (
        "value": 117,
        "min": 47px,
        "max": 110%,
    ),
    "116": (
        "value": 116,
        "min": 47px,
        "max": 110%,
    ),
    "115": (
        "value": 115,
        "min": 47px,
        "max": 110%,
    ),
    "114": (
        "value": 114,
        "min": 47px,
        "max": 110%,
    ),
    "113": (
        "value": 113,
        "min": 47px,
        "max": 110%,
    ),
    "112": (
        "value": 112,
        "min": 47px,
        "max": 110%,
    ),
    "111": (
        "value": 111,
        "min": 47px,
        "max": 110%,
    ),
    "110": (
        "value": 110,
        "min": 47px,
        "max": 110%,
    ),
    "109": (
        "value": 109,
        "min": 46px,
        "max": 110%,
    ),
    "108": (
        "value": 108,
        "min": 46px,
        "max": 110%,
    ),
    "107": (
        "value": 107,
        "min": 46px,
        "max": 110%,
    ),
    "106": (
        "value": 106,
        "min": 46px,
        "max": 110%,
    ),
    "105": (
        "value": 105,
        "min": 46px,
        "max": 110%,
    ),
    "104": (
        "value": 104,
        "min": 46px,
        "max": 110%,
    ),
    "103": (
        "value": 103,
        "min": 46px,
        "max": 110%,
    ),
    "102": (
        "value": 102,
        "min": 46px,
        "max": 110%,
    ),
    "101": (
        "value": 101,
        "min": 46px,
        "max": 110%,
    ),
    "100": (
        "value": 100,
        "min": 46px,
        "max": 110%,
    ),
    "99": (
        "value": 99,
        "min": 45px,
        "max": 110%,
    ),
    "98": (
        "value": 98,
        "min": 45px,
        "max": 110%,
    ),
    "97": (
        "value": 97,
        "min": 45px,
        "max": 110%,
    ),
    "96": (
        "value": 96,
        "min": 45px,
        "max": 110%,
    ),
    "95": (
        "value": 95,
        "min": 45px,
        "max": 110%,
    ),
    "94": (
        "value": 94,
        "min": 44px,
        "max": 110%,
    ),
    "93": (
        "value": 93,
        "min": 44px,
        "max": 110%,
    ),
    "92": (
        "value": 92,
        "min": 44px,
        "max": 110%,
    ),
    "91": (
        "value": 91,
        "min": 44px,
        "max": 110%,
    ),
    "90": (
        "value": 90,
        "min": 44px,
        "max": 110%,
    ),
    "89": (
        "value": 89,
        "min": 43px,
        "max": 110%,
    ),
    "88": (
        "value": 88,
        "min": 43px,
        "max": 110%,
    ),
    "87": (
        "value": 87,
        "min": 43px,
        "max": 110%,
    ),
    "86": (
        "value": 86,
        "min": 43px,
        "max": 110%,
    ),
    "85": (
        "value": 85,
        "min": 43px,
        "max": 110%,
    ),
    "84": (
        "value": 84,
        "min": 42px,
        "max": 110%,
    ),
    "83": (
        "value": 83,
        "min": 42px,
        "max": 110%,
    ),
    "82": (
        "value": 82,
        "min": 42px,
        "max": 110%,
    ),
    "81": (
        "value": 81,
        "min": 42px,
        "max": 110%,
    ),
    "80": (
        "value": 80,
        "min": 42px,
        "max": 110%,
    ),
    "79": (
        "value": 79,
        "min": 41px,
        "max": 110%,
    ),
    "78": (
        "value": 78,
        "min": 41px,
        "max": 110%,
    ),
    "77": (
        "value": 77,
        "min": 41px,
        "max": 110%,
    ),
    "76": (
        "value": 76,
        "min": 41px,
        "max": 110%,
    ),
    "75": (
        "value": 75,
        "min": 41px,
        "max": 110%,
    ),
    "74": (
        "value": 74,
        "min": 40px,
        "max": 110%,
    ),
    "73": (
        "value": 73,
        "min": 40px,
        "max": 110%,
    ),
    "72": (
        "value": 72,
        "min": 40px,
        "max": 110%,
    ),
    "71": (
        "value": 71,
        "min": 40px,
        "max": 110%,
    ),
    "70": (
        "value": 70,
        "min": 40px,
        "max": 110%,
    ),
    "69": (
        "value": 69,
        "min": 39px,
        "max": 110%,
    ),
    "68": (
        "value": 68,
        "min": 39px,
        "max": 110%,
    ),
    "67": (
        "value": 67,
        "min": 38px,
        "max": 110%,
    ),
    "66": (
        "value": 66,
        "min": 38px,
        "max": 110%,
    ),
    "65": (
        "value": 65,
        "min": 37px,
        "max": 110%,
    ),
    "64": (
        "value": 64,
        "min": 37px,
        "max": 110%,
    ),
    "63": (
        "value": 63,
        "min": 36px,
        "max": 110%,
    ),
    "62": (
        "value": 62,
        "min": 36px,
        "max": 110%,
    ),
    "61": (
        "value": 61,
        "min": 35px,
        "max": 110%,
    ),
    "60": (
        "value": 60,
        "min": 35px,
        "max": 110%,
    ),
    "59": (
        "value": 59,
        "min": 34px,
        "max": 110%,
    ),
    "58": (
        "value": 58,
        "min": 34px,
        "max": 110%,
    ),
    "57": (
        "value": 57,
        "min": 33px,
        "max": 110%,
    ),
    "56": (
        "value": 56,
        "min": 33px,
        "max": 110%,
    ),
    "55": (
        "value": 55,
        "min": 32px,
        "max": 110%,
    ),
    "54": (
        "value": 54,
        "min": 32px,
        "max": 110%,
    ),
    "53": (
        "value": 53,
        "min": 31px,
        "max": 110%,
    ),
    "52": (
        "value": 52,
        "min": 31px,
        "max": 110%,
    ),
    "51": (
        "value": 51,
        "min": 30px,
        "max": 110%,
    ),
    "50": (
        "value": 50,
        "min": 30px,
        "max": 110%,
    ),
    "49": (
        "value": 49,
        "min": 29px,
        "max": 110%,
    ),
    "48": (
        "value": 48,
        "min": 29px,
        "max": 110%,
    ),
    "47": (
        "value": 47,
        "min": 28px,
        "max": 110%,
    ),
    "46": (
        "value": 46,
        "min": 28px,
        "max": 110%,
    ),
    "45": (
        "value": 45,
        "min": 27px,
        "max": 110%,
    ),
    "44": (
        "value": 44,
        "min": 27px,
        "max": 110%,
    ),
    "43": (
        "value": 43,
        "min": 26px,
        "max": 110%,
    ),
    "42": (
        "value": 42,
        "min": 26px,
        "max": 110%,
    ),
    "41": (
        "value": 41,
        "min": 25px,
        "max": 110%,
    ),
    "40": (
        "value": 40,
        "min": 25px,
        "max": 110%,
    ),
    "39": (
        "value": 37,
        "min": 24px,    
        "max": 110%
    ),
    "38": (
        "value": 37,
        "min": 24px,    
        "max": 110%
    ),
    "37": (
        "value": 37,
        "min": 24px,    
        "max": 110%
    ),
    "36": (
        "value": 36,
        "min": 24px,    
        "max": 110%
    ),
    "35": (
        "value": 35,
        "min": 24px,    
        "max": 110%
    ),
    "34": (
        "value": 34,
        "min": 24px,    
        "max": 110%
    ),
    "33": (
        "value": 33,
        "min": 24px,    
        "max": 110%
    ),
    "32": (
        "value": 32,
        "min": 24px,    
        "max": 110%
    ),
    "31": (
        "value": 31,
        "min": 23px,    
        "max": 110%
    ),
    "30": (
        "value": 30,
        "min": 23px,    
        "max": 110%
    ),
    "29": (
        "value": 29,
        "min": 22px,    
        "max": 110%
    ),
    "28": (
        "value": 28,
        "min": 22px,    
        "max": 110%
    ),
    "27": (
        "value": 27,
        "min": 21px,    
        "max": 110%
    ),
    "26": (
        "value": 26,
        "min": 21px,    
        "max": 110%
    ),
    "25": (
        "value": 25,
        "min": 20px,    
        "max": 110%
    ),
    "24": (
        "value": 24,
        "min": 20px,
        "max": 110%
    ),
    "23": (
        "value": 23,
        "min": 19px,
        "max": 110%
    ),
    "22": (
        "value": 22,
        "min": 19px,
        "max": 110%
    ),
    "21": (
        "value": 21,
        "min": 18px,
        "max": 110%
    ),
    "20": (
        "value": 20,
        "min": 18px,
        "max": 110%
    ),
    "19": (
        "value": 19,
        "min": 17px,
        "max": 110%
    ),
    "18": (
        "value": 18,
        "min": 17px,
        "max": 110%
    ),
    "17": (
        "value": 17,
        "min": 16px,
        "max": 110%
    ),
    "16": (
        "value": 16,
        "min": 15px,
        "max": 110%
    ),
    "15": (
        "value": 15,
        "min": 15px,
        "max": 110%
    ),
    "14": (
        "value": 14,
        "min": 14px,
        "max": 110%
    ),
    "13": (
        "value": 13,
        "min": 13px,
        "max": 110%
    ),
    "12": (
        "value": 12,
        "min": 12px,
        "max": 110%
    )
);