.c-overlay-calendar {
    --overlay-calendar-width: 100%;
}

.c-overlay-calendar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    isolation: isolate;
    visibility: hidden;
    pointer-events: none;
    transition: visibility 0ms 600ms;

    &__container {
        position: absolute;
        top: 0;
        right: calc(0px - var(--overlay-calendar-width));
        bottom: 0;
        width: var(--overlay-calendar-width);
        height: calc(var(--vh, 1vh) * 100);
        background-color: rgb(var(--rgb-darker-blue));
        transition: right 600ms var(--in-out-cubic);
    }

    &__close-overlay-button {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-white));
        transition: background-color 300ms;

        &:hover {
            background-color: rgb(var(--rgb-dark-blue));
        }

        svg {
            width: 16px;
            height: 16px;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;
            transition: stroke 300ms;
        }

        &:hover svg {
            stroke: rgb(var(--rgb-white));
        }
    }

    &__overflow {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        scrollbar-width: none;

        &::-webkit-scrollbar { display: none; }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 120px;
        padding-bottom: 30px;
    }

    &__title {
        margin-bottom: 20px;

        font-size: var(--fz-30);
        font-weight: 600;
        line-height: 1.2em;
        color: rgb(var(--rgb-white));
    }

    .calendar {
        max-width: 500px;
    }

    &__button {
        display: flex;
        align-items: center;
        margin-top: 10px;

        span:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            border-radius: 50%;
            background-color: rgb(var(--rgb-white));
        }

        svg {
            width: 18px;
            height: 18px;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;
        }

        span:last-child {
            transition: opacity 300ms;

            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
        }

        &:hover span:last-child {
            opacity: 0.6;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: rgba(var(--rgb-white), 0.3);
        opacity: 0;
        transition: opacity 300ms;
    }
}

/*
|--------------------------------------------------------------------------
| SHOW OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-calendar {
    body {
        --alert-height: 0px;
    }

    .c-overlay-calendar {
        pointer-events: all;
        visibility: visible;
        transition: visibility 0ms 0ms;

        &__container {
            right: 0;
            pointer-events: all;
        }

        &__background {
            opacity: 1;
        }
    }
}


/*
|--------------------------------------------------------------------------
| CLOSING OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-calendar.closing-overlay-calendar {
    body {
        --alert-height: 0px;
    }

    .c-overlay-calendar {
        &__container {
            right: calc(0px - var(--overlay-calendar-width));
        }

        &__background {
            opacity: 0;
        }
    }
}