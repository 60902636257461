/*
|--------------------------------------------------------------------------
| Emplacement temporaire des styles des développeurs Back-end
|--------------------------------------------------------------------------
|
| Tous les ajouts que les programmeurs Back-end effectueront se
| retrouveront ici, afin de leur économiser du temps et garder le SCSS
| organisé. Idéalement, un développeur Front-end viendra répartir ce code
| dans les bons fichiers. Optimalement, ce fichier doit être vide. 
|
*/
