.o-background-filter {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    &:not(&--fixed) {
        position: absolute;
    }

    &--fixed {
        position: absolute;
    }
}