@use "../../base/typography";

#page-map {
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    z-index: 0;


    // Header block (accessible en mobile)
    .header-block-mobile {
        position: relative;
        display: none;
        align-items: center;
        justify-content: space-between;
        padding: 0 var(--x-40);
        height: var(--map-header-block-height);
        background-color: rgb(var(--rgb-white));
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
        z-index: 12;

        @media screen and (max-width: 1024px) {
            display: flex;
        }

        &__logo-container {
            display: block;
            width: fit-content;
        }

        &__logo-image {
            width: 188px;
            height: 64px;
            vertical-align: bottom;
        }
    }


    // Navigation block (accessible en mobile)
    .nav-block-mobile {
        position: relative;
        display: none;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
        z-index: 11;

        @media screen and (max-width: 1024px) {
            display: block;
        }

        &__button {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: var(--map-nav-block-height);
            background: rgb(var(--rgb-white));
            padding: 0 var(--x-40);
            z-index: 12;
        }

        &__button-text {
            font-family: var(--ff);
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.6em;
            color: rgb(var(--rgb-dark-blue));
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 20px;
            transition: color 300ms;
        }

        &__button-svg-container {
            position: relative;
            flex-shrink: 0;
            width: 7px;
            height: 11px;
        }

        &__button-svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(90deg);
            width: 100%;
            height: 100%;
            stroke: rgb(var(--rgb-lime));
            transition: transform 300ms;
        }
    }


    // Map block (API Google Maps)
    .map-block {
        &__google-map {
            position: absolute;
            width: calc(100% - var(--map-overlay-locations-width));
            height: calc(100% - calc(var(--map-header-block-height) + var(--map-nav-block-height)));
            top: calc(var(--map-header-block-height) + var(--map-nav-block-height));
            right: 0;

            @media screen and (max-width: 1024px) {
                width: 100%;
            }
        }
    }


    // Bouton close map
    .button-close-map-container {
        position: fixed;
        top: 30px;
        right: 40px;
        z-index: 8;

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    .button-close-map {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-lime));
        transition: background-color 300ms;

        @media screen and (max-width: 600px) {
            width: 42px;
            height: 42px;
        }

        &:hover {
            background-color: rgb(var(--rgb-dark-blue));
        }

        &__svg {
            width: 17px;
            height: 17px;
            fill: rgb(var(--rgb-white));
            rotate: 180deg;
            translate: -3px -1px;

            @media screen and (max-width: 600px) {
                width: 16px;
                height: 16px;
            }
        }
    }


    // Bouton see all
    .button-see-all-container {
        position: fixed;
        bottom: 25px;
        right: 80px;
        z-index: 8;

        @media screen and (max-width: 1024px) {
            right: auto;
            left: 25px;
        }

        @media screen and (max-width: 400px)  {
            left: 20px;
        }
    }

    .button-see-all {
        // @extend .c-button;
        // @extend .c-button--green-to-dark-blue;
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);
    }
}
