@use "../base/typography";

.c-footer {
    padding-right: 40px;
    padding-left: 40px;

    @media screen and (max-width: 800px) { padding: 0; }

    &.--with-button {
        @media screen and (max-width: 700px) { padding-bottom: var(--activity-button-height); }
    }

    &__scroll-to-top-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 25px;
    }

    &__scroll-to-top-line {
        display: block;
        flex: 1;
        height: 1px;
        background-color: rgba(var(--rgb-dark-blue), 0.3);
    }

    &__scroll-to-top {
        padding-right: 40px;
        padding-left: 40px;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        span {
            font-size: var(--fz-15);
            font-weight: 600;
            line-height: 1.6em;
            color: rgb(var(--rgb-dark-blue));
        }

        svg {
            rotate: -90deg;
            width: 18px;
            height: 18px;
            margin-left: 18px;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;
        }
    }

    &__container {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 405px;
        padding-bottom: 45px;
        padding-right: var(--x-nus-45);
        padding-left: var(--x-nus-110);
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
        background-color: rgb(var(--rgb-darker-blue));

        @media screen and (max-width: 1100px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: initial;
            height: auto;
            padding-right: var(--x-nus-110);
        }
        @media screen and (max-width: 800px) {
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
        }
    }

    &__triangles {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
    }

    &__triangle {
        position: absolute;
        bottom: 0;
        left: 26vw;

        @media screen and (max-width: 1100px) {
            top: 180px;
            right: 75px;
            bottom: initial;
            left: initial;
        }
        @media screen and (max-width: 600px) { right: -30px; }
    }

    &__tiny-triangle {
        position: absolute;
        bottom: 140px;
        left: 35vw;

        @media screen and (max-width: 1100px) {
            top: 100px;
            right: -40px;
            bottom: initial;
            left: initial;
        }
    }

    &__left {
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-top: max(var(--y-nus-90), 48px);

        @media screen and (max-width: 1100px) {
            justify-content: initial;
            height: auto;
        }
    }

    &__logo-container {
        width: 387px;
        height: 101px;

        @media screen and (max-width: 1100px) { margin-bottom: 30px; }
        @media screen and (max-width: 500px) {
            width: 325px;
            height: 84px;
        }
        @media screen and (max-width: 375px) {
            width: 275px;
            height: 71px;
        }
    }

    &__logo {
        width: 100%;
        height: 100%;
    }

    &__social-medias-and-copyright {
        width: 310px;

        @media screen and (max-width: 500px) { width: auto; }
    }

    &__link {
        display: flex;
        align-items: center;
        width: fit-content;
        margin-bottom: 20px;
        transition: opacity 300ms;

        span {
            margin-right: 8px;

            font-size: 1.6rem;
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-white));
        }

        svg {
            translate: 0 -2px;
            width: 10px;
            height: 10px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 3px;
        }

        &:hover {
            opacity: 0.5;
        }

        @media screen and (max-width: 500px) { margin-bottom: 11px; }
    }

    &__small-link-and-social-medias {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: initial;
            width: auto;
        }
    }

    &__copyright-item,
    &__small-link span {
        font-size: 1.2rem;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__copyright-item {
        display: inline-block;
        margin-bottom: 6px;
    }

    &__small-link {
        display: inline-block;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }

        @media screen and (max-width: 500px) { margin-bottom: 25px; }
    }

    &__social-medias-list {
        display: flex;
        align-items: flex-end;
    }

    &__social-medias-list-item {
        &:not(:last-child) {
            margin-right: 24px;
        }
    }

    &__social-media {
        display: block;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }

        svg {
            width: 22px;
            height: 22px;
            fill: rgb(var(--rgb-white));
        }
    }

    &__right {
        position: relative;
        z-index: 5;
        height: 100%;
        padding-top: 45px;

        @media screen and (max-width: 600px) { padding-top: 30px; }
    }

    &__cta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 650px;
        height: 100%;
        padding-top: max(var(--y-nus-70), 45px);
        padding-right: max(var(--x-nus-70), 30px);
        padding-bottom: max(var(--y-nus-70), 45px);
        padding-left: max(var(--x-nus-70), 30px);
        border-radius: 50px;
        background-color: rgb(var(--rgb-dark-blue));

        @media screen and (max-height: 700px) {
            height: 90%;
            padding-top: max(var(--y-nus-50), 45px);
            padding-right: max(var(--x-nus-50), 30px);
            padding-bottom: max(var(--y-nus-50), 45px);
            padding-left: max(var(--x-nus-50), 30px);
        }
        @media screen and (max-width: 1300px) { width: 550px; }
        @media screen and (max-width: 1200px) { width: 450px; }
        @media screen and (max-width: 1100px) {
            width: 100%;
            height: auto;
            min-height: 275px;
        }
        @media screen and (max-width: 600px) { border-radius: 25px; }
    }

    &__cta-title {
        font-size: clamp(2.4rem, var(--fz-28), 2.8rem);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));

        @media screen and (max-width: 1100px) { margin-bottom: 20px; }
    }

    &__cta-button {
        @extend .c-button;
        @extend .c-button--white-to-lime;
        @extend .c-button--svg-on-the-right;
    }
}