.c-event-infos {
    position: relative;
    padding-top: 65px;

    &__organisation {
        display: flex;
        align-items: flex-end;
        padding-bottom: 35px;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__organisation-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        margin-right: 20px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-light-blue));

        svg {
            width: 22px;
            height: 22px;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 1.5px;
        }
    }

    &__organisation-surtitle {
        display: block;
        margin-bottom: 2px;

        font-size: 1.4rem;
        line-height: 1.3em;
        color: rgba(var(--rgb-dark-blue), 0.8);
    }

    &__organisation-link {
        span {
            padding-bottom: 4px;
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-dark-blue), 0.4);
            transition: box-shadow 300ms var(--out-cubic);

            font-size: var(--fz-17);
            font-weight: 600;
            line-height: 1.6em;
            color: rgb(var(--rgb-dark-blue));
        }

        &:hover span {
            box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-dark-blue));
        }
    }

    &__bottom-section {
        padding-bottom: 35px;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__top-section + &__bottom-section {
        padding-top: 35px;
    }

    &__list,
    &__list-item {
        display: flex;
        align-items: center;
    }

    &__list {
        --publications-gap-x: 20px;
        --publications-gap-y: 10px;

        flex-wrap: wrap;
        width: calc(100% + (var(--publications-gap-x) * 2));
        margin-right: calc(0px - var(--publications-gap-x));
        margin-bottom: calc(0px - var(--publications-gap-y));
        margin-left: calc(0px - var(--publications-gap-x));
    }

    &__list-item {
        padding: 0 var(--publications-gap-x);
        margin-bottom: var(--publications-gap-y);

        svg {
            translate: 0 -1px;
            width: 20px;
            height: 20px;
            margin-right: 12px;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;
        }
    }

    &__svg.--pin {
        stroke-width: 1.5px;
    }

    &__list-text,
    &__list-link span {
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__list-link {
        span {
            padding-bottom: 4px;
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-dark-blue), 0.4);
            transition: box-shadow 300ms var(--out-cubic);

            font-weight: 600;
        }

        &:hover span {
            box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-dark-blue));
        }
    }
}