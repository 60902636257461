@use '../../abstract/placeholders';

.c-overlay-directory {
    --overlay-directory-width: 380px;
    @media screen and (max-width: 600px) { --overlay-directory-width: 100%; }

    --bottom-button-height: 75px;
}

.c-overlay-directory {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    isolation: isolate;
    visibility: hidden;
    pointer-events: none;
    transition: visibility 0ms 600ms;

    &__container {
        position: absolute;
        overflow: hidden;
        right: calc(0px - var(--overlay-directory-width));
        bottom: 0;
        width: var(--overlay-directory-width);
        height: 100%;
        max-height: 790px;
        background-color: rgb(var(--rgb-white));
        border-top-left-radius: 50px;
        transition: right 600ms var(--in-out-cubic);

        @media screen and (max-width: 600px) {
            max-height: 100%;
            border-radius: 0;
        }
    }

    &__close-overlay-button {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-light-blue));
        transition: background-color 300ms;

        &:hover {
            background-color: rgb(var(--rgb-dark-blue));
        }

        svg {
            width: 16px;
            height: 16px;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;
            transition: stroke 300ms;
        }

        &:hover svg {
            stroke: rgb(var(--rgb-white));
        }
    }

    &__content {
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        height: calc(100% - var(--bottom-button-height));
        padding-top: 70px;
        padding-right: var(--x-40);
        padding-left: var(--x-40);

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--rgb-dark-blue), 0.3);
            border-radius: 10px;
        }
    }

    &__category,
    &__subcategory {
        display: block;

        font-size: 1.4rem;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__category {
        margin-bottom: 4px;

        font-weight: 600;
    }

    &__subcategory {
        margin-bottom: 15px;
    }

    &__title {
        margin-bottom: 30px;

        font-size: var(--fz-25);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__picture-container,
    &__picture {
        width: 100%;
    }

    &__picture-container {
        @extend %border-radius;

        overflow: hidden;
        margin-bottom: 30px;
        border-radius: 25px;
    }

    &__picture {
        position: relative;
        padding-top: 55%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__address-surtitle,
    &__address {
        display: block;

        font-size: 1.5rem;
        line-height: 1.6em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__address-surtitle {
        margin-bottom: 5px;

        font-weight: 600;
    }

    &__address {
        margin-bottom: 10px;
    }

    // Gallery
    &__gallery {
        position: relative;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    &__gallery-block:not(&__gallery-block--block-1) {
        display: none;
    }

    &__gallery-link {
        @extend %border-radius;
        position: relative;
        overflow: hidden;
        display: block;
        height: 100%;
        outline: none;
        border-radius: 25px;
    }

    &__gallery-picture {
        width: 100%;
        padding-top: 55%;
    }

    &__gallery-filter {
        background-color: rgb(var(--rgb-dark-blue));
        opacity: 0;
        transition: opacity 300ms;
    }

    &__gallery--with-plus &__gallery-filter {
        opacity: 0.5;
    }

    &__gallery-link:hover &__gallery-filter {
        opacity: 0.5;
    }

    &__gallery--with-plus &__gallery-link:hover .c-overlay-directory__gallery-filter {
        opacity: 0.7;
    }

    &__gallery-plus-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }

    &__gallery-plus {
        width: 20px;
        height: 20px;

        &::before,
        &::after {
            height: 2px;
            background-color: rgb(var(--rgb-white));
        }
    }

    &__infos-list {
        padding-bottom: 30px;
    }

    &__info {
        display: flex;
        align-items: center;
        padding-top: 22px;
        padding-bottom: 22px;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);

        svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            fill: rgb(var(--rgb-dark-blue));
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 1.5px;
            flex-shrink: 0;
        }

        span {
            transition: opacity 300ms;

            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.6em;
            color: rgb(var(--rgb-dark-blue));
        }

        &:hover span {
            opacity: 0.6;
        }
    }

    &__bottom-button {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        height: var(--bottom-button-height);
        background-color: rgb(var(--rgb-dark-blue));

        line-height: var(--bottom-button-height);
        text-align: center;

        span {
            padding-bottom: 6px;
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-white), 0.4);
            transition: box-shadow 300ms var(--out-cubic);

            font-size: 1.5rem;
            font-weight: 600;
            color: rgb(var(--rgb-white));
        }

        &:hover span {
            box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-white));
        }
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: rgba(var(--rgb-dark-blue), 0.3);
        opacity: 0;
        transition: opacity 300ms;
    }
}

/*
|--------------------------------------------------------------------------
| SHOW OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-directory {
    body {
        --alert-height: 0px;
    }

    .c-overlay-directory {
        pointer-events: all;
        visibility: visible;
        transition: visibility 0ms 0ms;

        &__container {
            right: 0;
            pointer-events: all;
        }

        &__background {
            opacity: 1;
        }
    }
}


/*
|--------------------------------------------------------------------------
| CLOSING OVERLAY
|--------------------------------------------------------------------------
*/
.show-overlay-directory.closing-overlay-directory {
    body {
        --alert-height: 0px;
    }

    .c-overlay-directory {
        &__container {
            right: calc(0px - var(--overlay-directory-width));
        }

        &__background {
            opacity: 0;
        }
    }
}
