@use './buttons/button-hardcoded';
@use '../abstract/placeholders';

.c-to-discover {
    &__container {
        position: relative;
        isolation: isolate;
        padding-top: max(var(--y-120), 60px);
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        border-radius: 100px;
        background-color: rgb(var(--rgb-light-blue));

        @media screen and (max-height: 700px) { padding-top: max(var(--y-80), 60px); }
        @media screen and (max-width: 700px) {
            border-radius: 50px;
            padding-bottom: 25px;
        }
    }

    &__triangles {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        overflow: hidden;
    }

    &__triangle {
        position: absolute;
        right: calc(var(--x-60) + 20px);
        bottom: 160px;
        width: max(38vw, 180px);

        @media screen and (max-width: 1100px) {
            right: -45px;
            bottom: 550px;
        }
    }

    &__tiny-triangle {
        position: absolute;
        top: 0px;
        left: var(--x-60);
        width: max(20vw, 130px);

        @media screen and (max-width: 400px) { left: -30px; }
    }

    &__top-section {
        display: flex;
        justify-content: center;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__top-section-text {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.4em;
        letter-spacing: 0.1em;
        color: rgb(var(--rgb-dark-blue));
        text-transform: uppercase;
    }

    &__links {
        padding-bottom: max(var(--y-120), 60px);
        @media screen and (max-height: 700px) { padding-bottom: max(var(--y-80), 60px); }
    }

    &__links-section {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);

        @media screen and (max-height: 700px) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        @media screen and (max-width: 1000px) {
            flex-direction: column;
            flex-wrap: nowrap;
            padding: 0;
            border: none;
        }

        > span {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 30px;
            margin-left: 30px;
            border-radius: 50%;
            background-color: rgb(var(--rgb-dark-blue));

            @media screen and (max-width: 1000px) { display: none; }
        }
    }

    &__link-container {
        margin-right: -13px;

        @media screen and (max-width: 1000px) {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-top: 18px;
            padding-bottom: 18px;
            border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
        }
    }

    &__link {
        overflow: hidden;
        display: flex;
        align-items: center;
        width: fit-content;
        padding-top: 12px;
        padding-right: 25px;
        padding-bottom: 12px;
        padding-left: 12px;
        border-radius: 77px;
        transition: background-color 300ms linear 200ms;

        &:hover {
            background-color: rgb(var(--rgb-dark-blue));
            transition-delay: 0ms;
        }

        span:first-child {
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 62px;
            height: 62px;
            margin-right: 16px;
            margin-left: -78px;
            border-radius: 50%;
            background-color: rgb(var(--rgb-darker-blue));
            transition: margin-left 400ms var(--in-out-cubic) 0ms;
        }

        &:hover span:first-child {
            margin-left: 0;
            transition-delay: 200ms;
        }

        svg {
            width: 28px;
            height: 28px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
        }

        .thin {
            stroke-width: 1.5px;
        }

        span:last-child {
            font-size: var(--fz-50);
            line-height: 1.2em;
            color: rgb(var(--rgb-dark-blue));
            transition: color 300ms linear 200ms;

            @media screen and (max-width: 1300px) { font-size: var(--fz-45); }
            @media screen and (max-height: 700px) { font-size: var(--fz-45); }
            @media screen and (max-width: 1200px) { font-size: var(--fz-40); }
            @media screen and (max-width: 1000px) { text-align: center; }
        }

        &:hover span:last-child {
            color: rgb(var(--rgb-white));
            transition-delay: 0ms;
        }
    }

    &__showcases {
        display: none;
        padding-bottom: var(--y-70);

        @media screen and (max-width: 1100px) { flex-direction: column; }

        &:has( .c-to-discover__showcase) {
            display: flex;
        }
    }

    &__showcase {
        width: 50%;

        @media screen and (max-width: 1100px) { width: 100%; }

        &:first-child {
            padding-right: 30px;

            @media screen and (max-width: 1100px) {
                padding: 0;
                margin-bottom: 20px;
            }
        }

        &:not(:first-child) {
            padding-left: 30px;

            @media screen and (max-width: 1100px) { padding: 0; }
        }
    }

    &__showcase-content {
        position: relative;
        isolation: isolate;
        width: 100%;
        height: 100%;
    }

    &__picture-container {
        @extend %border-radius;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        overflow: hidden;
        border-radius: 50px;

        @media screen and (max-width: 600px) { border-radius: 25px; }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 50px;
            background: linear-gradient(0.18deg, rgba(27, 71, 96, 0.7) 0.16%, rgba(27, 71, 96, 0.21) 74.35%);

            @media screen and (max-width: 600px) { border-radius: 25px; }
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        min-height: 338px;
        padding-top: max(var(--y-120), 100px);
        padding-right: var(--x-60);
        padding-bottom: max(var(--y-45), 30px);
        padding-left: var(--x-60);

        @media screen and (max-width: 1100px) { min-height: 290px; }
    }

    &__showcase-title {
        margin-bottom: 20px;

        font-size: max(var(--fz-28), 2.4rem);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__showcase-button {
        @extend .c-button-hardcoded;
        @extend .c-button-hardcoded--transparent;

        display: block;
        flex-shrink: 0;
        pointer-events: none;
    }

    &__showcase:hover &__showcase-button {
        &::after {
            translate: 0;
        }

        span {
            color: rgb(var(--rgb-dark-blue));
        }
    }

    &__showcase-category {
        position: absolute;
        top: 25px;
        right: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 62px;
        height: 62px;
        border-radius: 50%;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            border-radius: 50%;
            background-color: rgb(var(--rgb-white));
            transition-property: top, right, bottom, left, background-color;
            transition-duration: 300ms;
            transition-timing-function: linear;
        }

        svg {
            width: 26px;
            height: 26px;
            fill: none;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 8px;
            transition: stroke 300ms;
        }
    }

    &__showcase:hover &__showcase-category::after {
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        background-color: rgb(var(--rgb-lime));
    }

    &__showcase:hover &__showcase-category svg {
        stroke: rgb(var(--rgb-white));
    }
}