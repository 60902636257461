@use './buttons/button-hardcoded';

.c-next-element {
    &__container {
        padding-right: max(var(--x-65), 40px);
        padding-bottom: max(var(--y-50), 40px);
        padding-left: max(var(--x-65), 40px);
        border-radius: 50px;
        background-color: rgb(var(--rgb-light-blue));

        @media screen and (max-width: 600px) { border-radius: 25px; }
    }

    &__top-section {
        padding-top: 30px;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__top-section-text {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.4em;
        letter-spacing: 0.1em;
        color: rgb(var(--rgb-dark-blue));
        text-transform: uppercase;
    }

    &__bottom-section {
        padding-top: 40px;
    }

    &__date {
        display: inline-block;
        margin-bottom: 14px;

        font-size: max(var(--fz-18), 1.6rem);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__title {
        margin-bottom: 30px;

        font-size: max(var(--fz-35), 2.6rem);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));

        @media screen and (max-width: 700px) { margin-bottom: 22px; }
    }

    &__buttons {
        display: flex;

        @media screen and (max-width: 500px) { flex-direction: column; }
    }

    &__button {
        &:first-child {
            @extend .c-button-hardcoded;
            @extend .c-button-hardcoded--white;

            margin-right: 8px;

            &::before {
                border-color: rgb(var(--rgb-white));
            }

            @media screen and (max-width: 500px) {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
        &:last-child {
            @extend .c-button-hardcoded;
            @extend .c-button-hardcoded--light-blue;

            &::before {
                border: 1px solid rgba(var(--rgb-dark-blue), 0.3);
            }
        }
    }
}