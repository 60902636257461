.c-events {
    &__date-and-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: -7px;
        margin-bottom: 20px;

        @media screen and (max-width: 400px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__date {
        display: inline-block;
        padding-top: 7px;

        font-size: var(--fz-24);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));

        @media screen and (max-width: 400px) { margin-bottom: 10px; }
    }

    &__refresh-button {
        display: flex;
        align-items: center;

        span:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            border-radius: 50%;
            background-color: rgb(var(--rgb-light-blue));
            transition: background-color 300ms;
        }

        &:hover span:first-child {
            background-color: rgb(var(--rgb-dark-blue));
        }

        svg {
            width: 18px;
            height: 18px;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;
            transition: stroke 300ms;
        }

        &:hover svg {
            stroke: rgb(var(--rgb-white));
        }

        span:last-child {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.6em;
            color: rgb(var(--rgb-dark-blue));
        }
    }

    &__list-item {
        max-width: 900px;
        margin-bottom: 60px;
    }
}