@use "../../abstract/placeholders";
@use '../buttons/button-hardcoded';

.c-publication-card {
    &__container {
        display: flex;
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);

        @media screen and (max-width: 700px) { flex-direction: column; }
    }

    &__picture-container {
        @extend %border-radius;

        position: relative;
        flex-shrink: 0;
        align-self: flex-start;
        overflow: hidden;
        width: 376px;
        border-radius: 50px;

        @media screen and (max-width: 1000px) { width: 326px; }
        @media screen and (max-width: 800px) { width: 276px; }
        @media screen and (max-width: 700px) {
            align-self: initial;
            width: 100%;
            margin-bottom: 30px;
            border-radius: 25px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            border-radius: 50px;
            background-color: rgb(var(--rgb-dark-blue));
            opacity: 0;
            transition: opacity 300ms;

            @media screen and (max-width: 700px) { border-radius: 25px; }
        }
    }

    &:hover &__picture-container::before {
        opacity: 0.5;
    }

    &__category {
        position: absolute;
        top: 22px;
        left: 24px;
        z-index: 2;
        display: flex;
        align-items: center;
        padding-top: 8px;
        padding-right: 14px;
        padding-bottom: 8px;
        padding-left: 14px;
        border-radius: 33px;
        background: rgba(0, 0, 0, 0.3);
        transition: background-color 300ms;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);

        @media screen and (max-width: 700px) {
            top: 14px;
            left: 12px;
        }

        svg {
            width: 18px;
            height: 18px;
            margin-right: 8px;
            fill: none;
            stroke: rgb(var(--rgb-white));
            stroke-width: 8px;
            transition: stroke 300ms;
        }

        span {
            padding-top: 1px;

            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.4em;
            color: rgb(var(--rgb-white));
            transition: color 300ms;
        }
    }

    &:hover &__category {
        background-color: rgb(var(--rgb-white));

        svg {
            stroke: rgb(var(--rgb-dark-blue));
        }

        span {
            color: rgb(var(--rgb-dark-blue));
        }
    }

    &__picture {
        position: relative;
        width: 100%;
        padding-top: 71.1%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__text-block {
        align-self: center;
        padding-left: var(--x-70);

        @media screen and (max-width: 700px) {
            align-self: initial;
            width: 100%;
            padding-left: 0;
        }
    }

    &__date {
        display: inline-block;
        margin-bottom: 12px;

        font-size: max(var(--fz-18), 1.6rem);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-blue));

        @media screen and (max-height: 700px) { font-size: 1.6rem; }
    }

    &__title {
        margin-bottom: 30px;

        font-size: max(var(--fz-35), 2.6rem);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));

        @media screen and (max-height: 700px) { font-size: max(var(--fz-30), 2.6rem); }
        @media screen and (max-width: 700px) { margin-bottom: 22px; }
    }

    &__button {
        @extend .c-button-hardcoded;
        @extend .c-button-hardcoded--light-blue;

        pointer-events: none;
    }

    &:hover &__button {
        &::after {
            translate: 0;
        }

        span {
            color: rgb(var(--rgb-white));
        }
    }
}