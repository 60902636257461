@use '../base/typography';
@use './buttons/button-hardcoded';

.c-page-title {
    position: relative;
    isolation: isolate;

    &__container {
        isolation: isolate;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: var(--page-title-padding-top);
        transition: padding-top 500ms var(--in-out-cubic);
    }

    &__triangles {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        overflow: hidden;
    }

    &__triangle {
        position: absolute;
        top: 0;
        left: 0;
        width: max(28vw, 150px);
    }

    &__tiny-triangle {
        position: absolute;
        top: 200px;
        right: 70px;

        @media screen and (max-width: 900px) { display: none; }
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-right: var(--x-200);
        padding-left: var(--x-200);
        margin-bottom: var(--y-65);

        @media screen and (max-height: 700px) { margin-bottom: var(--y-45); }
    }

    &__title {
        @extend .c-huge-title;

        color: rgb(var(--rgb-dark-blue));
        text-align: center;

        @media screen and (max-width: 700px) { font-size: var(--fz-70); }
        @media screen and (max-width: 400px) { font-size: var(--fz-60); }

        &.u-font-size-80 {
            font-size: var(--fz-80);

            @media screen and (max-height: 800px) { font-size: var(--fz-70); }
            @media screen and (max-width: 700px) { font-size: var(--fz-70); }
            @media screen and (max-width: 400px) { font-size: var(--fz-60); }
        }

        &.u-font-size-70 {
            font-size: var(--fz-70);

            @media screen and (max-height: 800px) { font-size: var(--fz-60); }
            @media screen and (max-width: 700px) { font-size: var(--fz-60); }
            @media screen and (max-width: 400px) { font-size: var(--fz-50); }
        }
    }

    // Page de recherche
    &__result {
        font-size: var(--fz-70);
        font-weight: 600;
    }

    &__button {
        @extend .c-button-hardcoded;
        @extend .c-button-hardcoded--white;

        --stroke-width: 1.5px;

        margin-top: 35px;
        white-space: nowrap;
        will-change: transform;

        &::before {
            border: none;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        }

        @media screen and (max-width: 700px) { margin-top: 25px; }
    }

    &__category-and-date {
        display: flex;
        align-items: center;
        margin-top: 20px;
        gap: 5px
    }

    &__category {
        display: flex;
        align-items: center;
        padding-top: 8px;
        padding-right: 14px;
        padding-bottom: 8px;
        padding-left: 14px;
        border-radius: 33px;
        background-color: rgb(var(--rgb-white));
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        transition: background-color 300ms;

        &:hover {
            background-color: rgb(var(--rgb-dark-blue));
        }

        svg {
            width: 18px;
            height: 18px;
            margin-right: 8px;
            fill: none;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 8px;
            transition: stroke 300ms;
        }

        &:hover svg {
            stroke: rgb(var(--rgb-white));
        }

        span {
            padding-top: 1px;

            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.4em;
            color: rgb(var(--rgb-dark-blue));
            transition: color 300ms;
        }

        &:hover span {
            color: rgb(var(--rgb-white));
        }
    }

    &__date {
        display: inline-block;

        font-size: max(var(--fz-18), 1.6rem);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__category + &__date {
        margin-left: 20px;
    }

    &__picture-container &__button {
        position: absolute;
        top: 0;
        left: 50%;
        translate: -50% -50%;
        z-index: 5;
        margin-top: 0;
    }

    &__picture-container {
        position: relative;
        width: calc(100% - (var(--x-200) * 2));
    }

    &__photo-credits {
        position: absolute;
        top: 30px;
        right: 50px;
        z-index: 5;
        width: var(--tooltip-dimensions);
        height: var(--tooltip-dimensions);
        border-radius: 50%;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);

        @media screen and (max-width: 900px) { right: 30px; }
        @media screen and (max-width: 500px) {
            top: 10px;
            right: 10px;
        }
    }

    &__picture {
        @extend %border-radius;

        position: relative;
        width: 100%;
        border-radius: 50px;
        padding-top: 49%;
        transition: border-radius 400ms var(--in-out-cubic);

        @media screen and (max-width: 1000px) { border-radius: 45px; }
        @media screen and (max-width: 900px) { border-radius: 40px; }
        @media screen and (max-width: 800px) { border-radius: 35px; }
        @media screen and (max-width: 700px) { border-radius: 30px; }
        @media screen and (max-width: 600px) { border-radius: 25px; }
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__search {
        width: 100%;
        padding-right: var(--x-100);
        padding-left: var(--x-100);
        margin-top: -25px;
    }

    .c-search-field {
        position: relative;
        width: 100%;
        height: 100px;

        @media screen and (max-width: 500px) { height: 80px; }

        .c-search-field__input-container {
            width: 100%;
            height: 100%;
        }

        .c-search-field__input {
            width: 100%;
            height: 100%;
            padding-right: 280px;
            padding-left: 25px;
            border: none;
            border-radius: 25px;
            box-shadow: 0 0 0 100px rgb(var(--rgb-white)) inset;
            background-color: rgb(var(--rgb-white));

            font-family: var(--ff);
            font-size: 2.4rem;
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-dark-blue));

            &::-webkit-input-placeholder { color: rgba(var(--rgb-dark-blue), 0.5); }
            &::-moz-placeholder { color: rgba(var(--rgb-dark-blue), 0.5); }
            &:-ms-input-placeholder { color: rgba(var(--rgb-dark-blue), 0.5); }
            &:-moz-placeholder { color: rgba(var(--rgb-dark-blue), 0.5); }
            &::placeholder { color: rgba(var(--rgb-dark-blue), 0.5); }

            @media screen and (max-width: 700px) {
                padding-right: 110px;
                font-size: 2rem;
            }
            @media screen and (max-width: 500px) { font-size: 1.8rem; }
            @media screen and (max-width: 400px) { font-size: 1.7rem; }
        }

        .c-search-field__search-button {
            @extend .c-button;
            @extend .c-button--white-to-lime;
            @extend .c-button--svg-on-the-right;

            position: absolute;
            top: 50%;
            right: 25px;
            translate: 0 -50%;

            @media screen and (max-width: 700px) { padding-left: 6px; }

            span:first-child {
                @media screen and (max-width: 700px) { display: none; }
            }

            span:last-child {
                @media screen and (max-width: 700px) {
                    width: 40px;
                    height: 40px;
                    margin-left: 0;
                }
            }
        }

        .c-search-field__x {
            position: absolute;
            top: 50%;
            right: 240px;
            translate: 0 -50%;
            display: block;
            width: 16px;
            height: 16px;
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;

            @media screen and (max-width: 700px) { right: 85px; }

            svg {
                width: 100%;
                height: 100%;
                stroke: rgb(var(--rgb-dark-blue));
                stroke-width: 2px;
                transition: opacity 300ms;
            }
        }

        &[data-inputvalue]:not([data-inputvalue=""]) .c-search-field__x {
            opacity: 1;
            pointer-events: all;
            transition: opacity 300ms;

            &:hover svg {
                opacity: 0.6;
            }
        }
    }

    &__absolute-container {
        width: 100%;
    }

    &.--with-image &__absolute-container {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin-top: 0;

        @media screen and (min-width: 1101px) {
            top: 0;
            right: 0;
        }
    }

    &.--with-image &__sticky-container {
        @media screen and (min-width: 1101px) {
            position: sticky;
            top: 0;
            display: flex;
            align-items: flex-end;
            height: 100vh;
        }
    }

    &__bottom-section {
        position: relative;
        width: 100%;
        height: var(--page-title-bottom-section-height);
        background-color: rgb(var(--rgb-white));
        border-radius: 100px 100px 0px 0px;
    }

    &__filter + &__bottom-section {
        margin-top: 0;
    }

    &__search + &__bottom-section {
        margin-top: 25px;
    }

    .c-button-share,
    .c-button-scrolldown {
        position: absolute;
        top: 55px;

        @media screen and (max-width: 1100px) { display: none; }
    }

    .c-button-share {
        left: 50px;

        @media screen and (max-width: 1400px) { left: 30px; }
        @media screen and (max-width: 1200px) { left: 20px; }
    }

    .c-button-scrolldown {
        right: 50px;

        @media screen and (max-width: 1400px) { right: 30px; }
        @media screen and (max-width: 1200px) { right: 20px; }
    }

    &__filter {
        width: 100%;
        padding-right: var(--x-100);
        padding-left: var(--x-100);
    }
}

.--page-publication .c-page-title {
    &__picture-container {
        max-width: 806px;
    }

    &__picture {
        padding-top: 70.4%;
    }
}