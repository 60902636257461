.c-selects {
    position: absolute;
    top: calc(var(--page-title-padding-top) + var(--page-title-Height) + 25px);
    right: 0;
    left: 0;
    z-index: 5;
    transition: top 500ms var(--in-out-cubic);

    &__container {
        display: flex;
        justify-content: center;
        width: 100%;
        height: var(--selects-height);
        padding-right: var(--x-200);
        padding-left: var(--x-200);

        @media screen and (max-width: 700px) {
            flex-direction: column;
            align-items: center;
            justify-content: initial;
        }
    }

    .c-select-field + .c-select-field {
        margin-left: 12px;

        @media screen and (max-width: 700px) {
            margin-left: 0;
            margin-top: 20px;
        }
    }
}