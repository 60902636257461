/*
|--------------------------------------------------------------------------
| Page Transition
|--------------------------------------------------------------------------
|
| CSS des transitions de pages
|
*/

.page-transition {
    --page-transition-duration-out: 400ms;
    --page-transition-duration-in: 400ms;
}

.page-transition {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    z-index: 1000;
    transition: visibility 0ms var(--page-transition-duration-in);

    &__container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgb(var(--rgb-white));
        // box-shadow: 0 0 20px 0 rgba(var(--rgb-black), 0.15);
        // opacity: 1;
        opacity: 0;
        transition: opacity var(--page-transition-duration-in) var(--in-out-cubic);
    }
}


/*
|--------------------------------------------------------------------------
| TRANSITION PAGE VISIBLE
|--------------------------------------------------------------------------
*/
.show-page-transition {
    .page-transition {
        visibility: visible;
        transition: visibility 0ms 0ms;

        &__container {
            opacity: 1;
            transition: opacity var(--page-transition-duration-out) var(--in-out-cubic);
        }
    }
}
