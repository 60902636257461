.c-button-scrolldown {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(var(--rgb-white));

    svg {
        rotate: 90deg;
        width: 22px;
        height: 22px;
        stroke: rgb(var(--rgb-dark-blue));
        stroke-width: 2px;
        transition: opacity 300ms;
    }

    &:hover svg {
        opacity: 0.6;
    }
}