@use '../base/typography';

.o-section {
    --left-aside-width: 376px;
    @media screen and (max-width: 1100px) { --left-aside-width: 0px; }

    --right-aside-width: 275px;
    @media screen and (max-width: 1440px) { --right-aside-width: 255px; }
    @media screen and (max-width: 1100px) { --right-aside-width: 0px; }
}

.o-section {
    &.--cms-content {
        margin-top: var(--y-65);
        margin-bottom: var(--y-100);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--small-documents {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--documents {
        margin-top: var(--y-100);
    }

    &.--gallery {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--rubrics {
        margin-top: var(--y-100);
    }

    &.--form {
        margin-top: var(--y-80);
        margin-right: var(--x-40);
        margin-bottom: var(--y-80);
        margin-left: var(--x-40);
        padding-top: var(--y-100);
        padding-right: max(var(--x-90), 40px);
        padding-bottom: var(--y-75);
        padding-left: max(var(--x-90), 40px);
    }

    &.--page-title + &.--form {
        margin-top: 0;
    }

    &.--rubrics + &.--form {
        margin-top: -34px;
    }

    &.--showcase {
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
        padding-right: var(--x-120);
        padding-left: var(--x-120);
    }

    &.--publications {
        margin-bottom: var(--y-100);
        padding-right: var(--x-120);
        padding-left: var(--x-120);
    }

    &.--pagination {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-120);
        padding-left: var(--x-120);
    }

    &.--next-element {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--publications-component {
        margin-top: 60px;
        margin-bottom: var(--y-100);
        padding-right: var(--x-120);
        padding-left: var(--x-120);
    }

    &.--events {
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &.--with-left-aside {
        padding-left: var(--x-120);
        margin-bottom: var(--y-100);

        @media screen and (max-width: 1100px) { padding-left: 0; }
    }

    &.--with-right-aside {
        padding-right: var(--x-130);
        margin-bottom: var(--y-100);

        @media screen and (max-width: 1100px) { padding-right: 0; }
    }

    &.--event-infos {
        margin-bottom: 35px;
        padding-right: var(--x-240);
        padding-left: var(--x-240);
    }

    &.--events-component {
        padding-top: var(--y-100);
        padding-bottom: var(--y-100);
    }

    &.--next-element + &.--events-component {
        padding-top: 0;
    }

    &.--home-banner + &.--events-component {
        padding-top: 45px;
    }

    &.--featured-categories {
        margin-bottom: var(--y-100);
        padding-right: var(--x-120);
        padding-left: var(--x-120);
    }

    &.--search-results {
        margin-bottom: var(--y-100);
        padding-right: calc(var(--x-100) + 25px);
        padding-left: calc(var(--x-100) + 25px);

        @media screen and (max-width: 400px) {
            padding-right: var(--x-100);
            padding-left: var(--x-100);
        }
    }

    &.--contact-infos {
        margin-top: 30px;
        margin-bottom: var(--y-75);
        padding-right: var(--x-200);
        padding-left: var(--x-200);
    }

    &.--to-discover {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-40);
        padding-left: var(--x-40);
    }

    &.--rubrics + .o-section:not(.--form),
    &.--documents + .o-section:not(.--form) {
        margin-top: 0;
        padding-top: 0;
    }

    &.--page-title + section:not(.--form) {
        margin-top: calc(0px - var(--page-title-bottom-section-height));
        padding-top: 60px;

        @media screen and (max-width: 700px) { padding-top: 40px; }

        .c-event-infos {
            padding-top: 0;
        }
    }

    &.--events-component + section {
        margin-top: 0;
    }

    &.--content-with-left-aside section {
        @media screen and (min-width: 1101px) { padding-left: var(--x-100); }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.--content-with-right-aside section {
        @media screen and (min-width: 1101px) { padding-right: var(--x-100); }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

// Styles custom
.o-section {
    &.--home-banner {
        position: relative;
        z-index: 1;
    }

    &.--page-title {
        background-color: rgb(var(--rgb-light-blue));
    }

    &.--rubrics,
    &.--documents {
        .c-interface-header {
            padding-right: var(--x-240);
            padding-left: var(--x-240);
        }

        .c-small-document-card {
            background-color: rgb(var(--rgb-white));
        }
    }

    &.--form {
        border-radius: 100px;
        background-color: rgb(var(--rgb-light-blue));

        @media screen and (max-width: 800px) { border-radius: 75px; }
        @media screen and (max-width: 600px) { border-radius: 60px; }
    }

    &.--with-left-aside,
    &.--with-right-aside {
        display: flex;
    }

    &.--with-left-aside {
        position: relative;
    }

    &.--left-aside,
    &.--right-aside {
        @media screen and (max-width: 1100px) { display: none; }

        .c-aside {
            height: 100%;
        }
    }

    &.--left-aside {
        width: var(--left-aside-width);

        .calendar-zone {
            position: sticky;
            top: 60px;
        }
    }

    &.--right-aside {
        width: var(--right-aside-width);

        .c-select-field {
            position: sticky;
            top: 60px;
        }
    }

    &.--content-with-left-aside {
        width: calc(100% - var(--left-aside-width));
    }

    &.--content-with-right-aside {
        width: calc(100% - var(--right-aside-width));
    }

    &.--events-component {
        position: relative;
        isolation: isolate;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            height: 40%;
            background-color: rgb(var(--rgb-light-blue));
            opacity: 0;
        }
    }

    &.--publications-component {
        .triangles {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            overflow: hidden;
        }

        .triangle {
            position: absolute;
            bottom: -20px;
            left: 0;

            @media screen and (max-width: 600px) { display: none; }
        }
    }

    &.--sticky-buttons {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
    }
}

.o-layout.--page-home {
    .o-section.--events-component {
        @media screen and (max-width: 600px) {
            position: relative;

            &::before {
                opacity: 1;
            }
        }
    }
}

.o-layout.--page-search {
    .o-section.--with-right-aside {
        padding-right: calc(var(--x-100) + 25px);
    }
}

.o-layout.--page-jobs {
    .o-section.--documents {
        margin-bottom: 0;
    }

    .o-section.--documents + .o-section.--form {
        margin-top: 0;
    }
}