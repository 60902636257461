.c-pagination {
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &__pages-list {
        display: flex;
        align-items: center;
        margin-right: var(--x-40);
        margin-left: var(--x-40);

        @media screen and (max-width: 400px) { margin-right: 15px; margin-left: 15px; }
    }

    &__button-prev,
    &__button-next {
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        transition: opacity 300ms;

        &.--inactive {
            opacity: 0.3;
            pointer-events: none;
        }

        &:hover {
            opacity: 0.6;
        }

        svg {
            width: 100%;
            height: 100%;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;
        }
    }

    &__button-prev {
        rotate: 180deg;
    }

    &__pages-list-item:not(:last-child) {
        margin-right: 6px;

        @media screen and (max-width: 400px) { margin-right: 0; }
    }

    &__page {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;

        &.--active {
            background-color: rgb(var(--rgb-light-blue));
            pointer-events: none;
        }

        span {
            padding-top: 1px;

            font-size: 1.7rem;
            font-weight: 500;
            line-height: 1.3em;
            color: rgba(var(--rgb-dark-blue), 0.8);
            transition: color 300ms;
        }

        &.--active span,
        &:hover span {
            color: rgb(var(--rgb-dark-blue));
        }
    }
}