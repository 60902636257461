.c-sticky-buttons {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;

    &__sticky-buttons {
        position: relative;
        height: 100%;
    }

    &__sticky-content {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        width: fit-content;
        height: 100vh;
        padding-bottom: 20px;

        @media screen and (max-width: 600px) {
            height: calc(100vh - var(--activity-button-height));
            padding-bottom: 15px;
        }
    }
}