@use "../../abstract/placeholders";

.c-large-document-card {
    --border-radius: 100px;

    @media screen and (max-width: 800px) { --border-radius: 75px; }
    @media screen and (max-width: 600px) { --border-radius: 60px; }
}

.c-large-document-card {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 150px;
    padding: 30px max(var(--x-240), 30px);
    border-top: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    border-right: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    border-bottom: 1px solid transparent;
    border-left: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    transition: background-color 300ms, border-color 0ms 300ms, border-radius 100ms var(--in-out-quart) 300ms;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    @media screen and (max-width: 1024px) { min-height: 120px; }
    @media screen and (max-width: 768px)  { min-height: var(--border-radius); }

    &__svg-container {
        flex-shrink: 0;

        font-size: 0;
    }

    &.--job &__svg-container {
        display: none;
    }

    &__svg {
        width: 20px;
        height: 20px;
        stroke: rgb(var(--rgb-dark-blue));
        stroke-width: 2px;
        transition: opacity 300ms;
    }

    &:hover &__svg {
        opacity: 0.6;
    }

    &__content {
        flex: 1;
    }

    &__svg-container:not(.--job .c-large-document-card__svg-container) + &__content {
        padding-left: 35px;
    }

    &__surtitle {
        display: block;
        margin-bottom: 3px;
        transition: opacity 300ms;

        font-size: 1.3em;
        font-weight: 600;
        line-height: 1.6em;
        color: rgb(var(--rgb-dark-blue));
    }

    &:hover &__surtitle {
        opacity: 0.6;
    }

    &__title {
        transition: opacity 300ms;

        font-size: var(--fz-26);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));
    }

    &:hover &__title {
        opacity: 0.6;
    }

    &__subtitle {
        display: inline-block;
        margin-top: 14px;

        span {
            padding-bottom: 4px;
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-dark-blue), 0.4);
            transition: box-shadow 300ms var(--out-cubic);

            font-size: var(--fz-17);
            font-weight: 600;
            line-height: 1.6em;
            color: rgb(var(--rgb-dark-blue));
        }
    }

    &:hover &__subtitle span {
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-dark-blue));
    }

    &__picture {
        @extend %border-radius;

        overflow: hidden;
        flex-shrink: 0;
        width: 143px;
        height: 90px;
        margin-left: 50px;
        border-radius: 25px;

        @media screen and (max-width: 1024px) { margin-left: 40px; }
        @media screen and (max-width: 768px)  { margin-left: 30px; }
        @media screen and (max-width: 600px) { display: none; }
    }
}
