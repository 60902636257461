.c-radio-field {
    --radio-field-title-color: rgb(var(--rgb-dark-blue));
    --radio-field-label-color: rgb(var(--rgb-dark-blue));
    --radio-field-dot-color: rgb(var(--rgb-white));
    --radio-field-box-background: rgb(var(--rgb-light-blue));
    --radio-field-box-background-active: rgb(var(--rgb-dark-blue));
    --radio-field-box-border: rgba(var(--rgb-dark-blue), 0.3);
    --radio-field-box-border-active: rgb(var(--rgb-dark-blue));
}

.c-radio-field {
    --radio-field-label-font-size: var(--fz-16);
    position: relative;

    &__header {
        margin-bottom: var(--y-nus-30);
    }

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__label {
        display: flex;
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &:last-child {
            margin-bottom: 0;
        }

        &--disabled {
            opacity: 0.6;
            pointer-events: none;
        }
    }

    &__text {
        flex: 1;
        font-family: var(--ff);
        font-size: var(--radio-field-label-font-size);
        line-height: 1.5em;
        color: var(--radio-field-label-color);
        order: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &__box {
        width: 20px;
        height: 20px;
        position: relative;
        margin-top: calc(((var(--radio-field-label-font-size) * 1.5) - 20px) / 2);
        margin-right: 15px;
        background-color: var(--radio-field-box-background);
        border: 1px solid var(--radio-field-box-border);
        border-radius: 50%;
        order: 1;
        transition-property: background-color, border-color;
        transition-duration: 225ms;

        &::before {
            content: "";
            width: 8px;
            height: 8px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            background-color: var(--radio-field-dot-color);
            border-radius: 50%;
            transition: transform 225ms;
        }
    }

    &__input:checked ~ &__box {
        background-color: var(--radio-field-box-background-active);
        border-color: var(--radio-field-box-border-active);

        &::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &--inline &__options {
        margin-top: -20px;
    }

    &--inline &__label {
        display: inline-flex;
        margin: 20px 30px 0 0;
    }
}
