@use "../base/typography";

.c-search-results {
    &__select {
        margin-bottom: 30px;

        @media screen and (min-width: 1101px) { display: none; }
    }

    &__search {
        margin-bottom: 30px;

        @media screen and (max-width: 1101px) { display: none; }
    }

    &__no-result-button {
        @extend .c-button;
        @extend .c-button--white-to-lime;
        @extend .c-button--svg-on-the-right;
    }
}