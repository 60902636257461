@use "../../abstract/placeholders";
@use '../buttons/button-hardcoded';
@use '../../base/typography';

.c-event-card {
    &__picture-container {
        @extend %border-radius;

        position: relative;
        flex-shrink: 0;
        overflow: hidden;
        width: 100%;
        border-radius: 50px;

        @media screen and (max-width: 600px) { border-radius: 25px; }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            border-radius: 50px;
            background-color: rgb(var(--rgb-dark-blue));
            opacity: 0;
            transition: opacity 300ms;

            @media screen and (max-width: 600px) { border-radius: 25px; }
        }
    }

    &__content:hover &__picture-container::before {
        opacity: 0.5;
    }

    &__category {
        position: absolute;
        top: 22px;
        left: 24px;
        z-index: 2;
        display: flex;
        align-items: center;
        padding-top: 8px;
        padding-right: 14px;
        padding-bottom: 8px;
        padding-left: 14px;
        border-radius: 33px;
        background: rgba(0, 0, 0, 0.3);
        transition: background-color 300ms;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);

        svg {
            width: 18px;
            height: 18px;
            margin-right: 8px;
            fill: none;
            stroke: rgb(var(--rgb-white));
            stroke-width: 8px;
            transition: stroke 300ms;
        }

        span {
            padding-top: 1px;

            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.4em;
            color: rgb(var(--rgb-white));
            transition: color 300ms;
        }
    }

    &__content:hover &__category {
        background-color: rgb(var(--rgb-white));

        svg {
            stroke: rgb(var(--rgb-dark-blue));
        }

        span {
            color: rgb(var(--rgb-dark-blue));
        }
    }

    &__picture {
        position: relative;
        width: 100%;
        padding-top: 71.1%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__text-block {
        padding-top: 30px;
        padding-bottom: 35px;
    }

    &__date {
        display: inline-block;
        margin-bottom: 12px;

        font-size: max(var(--fz-18), 1.6rem);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__title {
        font-size: max(var(--fz-35), 2.6rem);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__content {
        overflow: hidden;
        height: 0;
        will-change: height;
    }

    &__transition {
        padding-top: 24px;
        opacity: 0;
        transform: translateY(100px);
        transition-property: opacity, transform;
        transition-duration: 700ms;
        transition-timing-function: var(--in-out-quart);
    }

    &.js-accordion-opened &__transition {
        opacity: 1;
        transform: none;
        transition-delay: 100ms;
    }

    &__hours,
    &__place {
        display: flex;
        align-items: center;

        svg {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-right: 12px;
            stroke: rgb(var(--rgb-dark-blue));
        }

        span {
            @extend .c-paragraph;

            color: rgb(var(--rgb-dark-blue));
        }
    }

    &__hours + &__place {
        margin-top: 10px;
    }

    &__hours svg {
        stroke-width: 2px;
    }

    &__place svg {
        stroke-width: 1.5px;
    }

    &__buttons {
        display: flex;
        padding-top: 18px;
        border-top: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__button:first-child {
        @extend .c-button-hardcoded;
        @extend .c-button-hardcoded--light-blue;

        margin-right: 10px;
    }

    &__button:last-child {
        @extend .c-button-hardcoded;
        @extend .c-button-hardcoded--white;

        span {
            transition: opacity 300ms;
        }

        span:last-child {
            position: absolute;
            top: 11px;
            left: 24px;
            opacity: 0;
        }
    }

    &.js-accordion-opened &__button:last-child {
        span:first-child {
            opacity: 0;
        }

        span:last-child {
            opacity: 1;
        }
    }
}