.c-interface-header {
    &__container {
        margin-bottom: var(--y-30);
    }

    &__container > :first-child {
        margin-top: 0;
    }

    &__container > :last-child {
        margin-bottom: 0;
    }

    &__title {
        margin: var(--y-30) 0;
    }

    &__description {
        margin: var(--y-30) 0;
    }
}

