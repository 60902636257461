@use './buttons/button-hardcoded';
@use '../abstract/placeholders';

.c-home-banner {
    --text-block-width: 325px;
    --swipers-width: 768px;
    @media screen and (max-width: 1420px) { --swipers-width: 700px; }
    @media screen and (max-width: 1400px) { --swipers-width: 668px; }
    @media screen and (max-width: 1200px) { --swipers-width: 568px; }
    @media screen and (max-width: 1100px) { --swipers-width: 468px; }
}

.c-home-banner {
    &__container {
        position: relative;
        isolation: isolate;
        display: flex;
        height: calc((var(--vh, 1vh) * 100) - 170px);
        min-height: 500px;

        @media screen and (max-height: 800px) { height: calc((var(--vh, 1vh) * 100) - 100px); }
        @media screen and (max-width: 900px) {
            flex-direction: column;
            height: initial;
            min-height: 0;
            background-color: rgb(var(--rgb-light-blue));
        }
    }

    &__triangles {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        overflow: hidden;
    }

    &__triangle,
    &__triangle-white {
        position: absolute;
        top: 0;
        left: 0;
        width: max(28vw, 150px);
    }

    &__triangle {
        @media screen and (max-width: 900px) { display: none; }
    }

    &__triangle-white {
        @media screen and (min-width: 901px) { display: none; }
    }

    &__tiny-triangle,
    &__tiny-triangle-white {
        position: absolute;
        right: calc(var(--swipers-width) - 85px);
        bottom: 75px;

        @media screen and (max-width: 900px) {
            top: 270px;
            right: -60px;
            bottom: initial;
        }
        @media screen and (max-width: 600px) { top: 200px; }
    }

    &__tiny-triangle {
        @media screen and (max-width: 900px) { display: none; }
    }

    &__tiny-triangle-white {
        @media screen and (min-width: 901px) { display: none; }
    }

    &__left-section {
        flex: 1;
        padding-top: calc(var(--header-height) + var(--alert-height) + var(--y-80));
        padding-right: 30px;
        padding-bottom: max(var(--y-80), 60px);
        padding-left: var(--x-100);
        transition: padding-top 500ms var(--in-out-cubic);

        @media screen and (max-width: 600px) { padding-top: calc(var(--header-height) + var(--alert-height) + 25px); }
    }

    &__title {
        font-size: var(--fz-65);
        line-height: 1.2em;
        color: rgb(var(--rgb-dark-blue));

        @media screen and (max-width: 1400px) { font-size: var(--fz-55); }
    }

    &__fancy-text-container {
        display: flex;
        align-items: center;
        width: fit-content;
        padding-top: 9px;
        padding-right: 40px;
        padding-bottom: 9px;
        padding-left: 16px;
        margin-top: 20px;
        border-radius: 90px;
        background-color: rgb(var(--rgb-dark-blue));

        @media screen and (max-width: 600px) {
            padding-top: 6px;
            padding-right: 25px;
            padding-bottom: 6px;
            padding-left: 10px;
            margin-top: 15px;
        }

        span:not(.c-home-banner__svg-container) {
            font-size: max(var(--fz-65), 4rem);
            font-weight: 600;
            line-height: 1.3em;
            color: rgb(var(--rgb-white));
        }
    }

    &__svg-container {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 82px;
        height: 82px;
        margin-right: 16px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-darker-blue));

        @media screen and (max-width: 600px) {
            width: 50px;
            height: 50px;
            margin-right: 10px;
        }

        svg {
            width: 36px;
            height: 36px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;

            @media screen and (max-width: 600px) {
                width: 22px;
                height: 22px;
            }
        }

        .swiper,
        .swiper-wrapper,
        .swiper-slide {
            height: 100%;
        }

        .swiper-slide {
            display: flex;
            align-items: center;
        }
    }

    &__swipers {
        position: relative;
        width: var(--swipers-width);
        height: 100%;

        @media screen and (max-width: 900px) { width: 100%; }
        @media screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        [data-swiper="home-banner-image"] {
            @extend %border-radius;

            overflow: hidden;
            width: 100%;
            height: 100%;
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;

            @media screen and (max-width: 900px) {
                border-top-left-radius: 50px;
                border-top-right-radius: 50px;
                border-bottom-left-radius: 0;
            }
        }

        [data-swiper="home-banner-text"] {
            position: absolute;
            right: 30px;
            bottom: 30px;
            width: var(--text-block-width);
            min-height: 335px;
            border-radius: 50px;
            background-color: rgb(var(--rgb-white));

            @media screen and (max-width: 600px) {
                position: relative;
                right: initial;
                bottom: initial;
                width: calc(100% - (var(--x-80) * 2));
                min-height: 0;
                margin-top: -95px;
                border-radius: 25px;
            }
        }
    }

    &__picture-container,
    &__picture {
        width: 100%;
        height: 100%;

        @media screen and (max-width: 900px) { height: initial; }
    }

    &__photo-credits {
        position: absolute;
        bottom: 30px;
        left: 50px;
        z-index: 5;
        width: var(--tooltip-dimensions);
        height: var(--tooltip-dimensions);
        border-radius: 50%;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        pointer-events: none;

        @media screen and (max-width: 600px) {
            top: 30px;
            right: 30px;
            bottom: initial;
            left: initial;
        }
    }

    .swiper-slide-active .c-home-banner__photo-credits {
        pointer-events: all;
    }

    &__picture {
        position: relative;

        @media screen and (max-width: 900px) { padding-top: 98.7%; }
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__text-block {
        padding-top: 50px;
        padding-right: 30px;
        padding-bottom: 80px;
        padding-left: 40px;
    }

    &__text-block-title {
        font-size: max(var(--fz-24), 2.2rem);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__button {
        @extend .c-button-hardcoded;
        @extend .c-button-hardcoded--light-blue;

        margin-top: 20px;
    }

    &__swiper-pagination {
        position: absolute;
        right: 48px;
        bottom: 55px;
        z-index: 5;
        width: calc(var(--text-block-width) - 58px);
        height: 30px;

        @media screen and (max-width: 600px) {
            right: initial;
            bottom: 25px;
            left: calc(var(--x-80) + 40px);
        }
    }

    .swiper-pagination {
        --swiper-pagination-color: rgb(var(--rgb-dark-blue));
        --swiper-pagination-bullet-size: 7px;
        --swiper-pagination-bullet-width: 7px;
        --swiper-pagination-bullet-height: 7px;
        --swiper-pagination-bullet-inactive-color: rgb(var(--rgb-dark-blue));
        --swiper-pagination-bullet-inactive-opacity: 0.3;
        --swiper-pagination-bullet-opacity: 1;
        --swiper-pagination-bullet-horizontal-gap: 12px;
        --swiper-pagination-bullet-border-radius: 50%;

        text-align: initial!important;
    }

    .swiper-pagination .swiper-pagination-bullet {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: rgba(var(--rgb-dark-blue), 0.1);
            opacity: 0;
            transition: opacity 300ms;
        }
    }

    .swiper-pagination .swiper-pagination-bullet-active {
        &::after {
            opacity: 1;
        }
    }
}

// Animation avec le scrollfire
.c-home-banner {
    &__title,
    &__fancy-text-container,
    [data-swiper="home-banner-image"],
    [data-swiper="home-banner-text"],
    .swiper-pagination {
        @extend %scrollfire-properties;
    }

    &[data-fire] &__title,
    &[data-fire] &__fancy-text-container,
    &[data-fire] [data-swiper="home-banner-image"],
    &[data-fire] [data-swiper="home-banner-text"],
    &[data-fire] .swiper-pagination {
        translate: 0;
        opacity: 1;
    }
}