.c-featured-categories {
    &__top-section {
        display: flex;
        justify-content: center;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__top-section-text {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.4em;
        letter-spacing: 0.1em;
        color: rgb(var(--rgb-dark-blue));
        text-transform: uppercase;
    }

    &__list {
        --categories-gap-x: 16px;
        --categories-gap-y: 10px;

        display: flex;
        flex-wrap: wrap;
        width: calc(100% + (var(--categories-gap-x) * 2));
        padding-top: 20px;
        margin-right: calc(0px - var(--categories-gap-x));
        margin-bottom: calc(0px - var(--categories-gap-y));
        margin-left: calc(0px - var(--categories-gap-x));
    }

    &__list-item {
        width: 33.33%;
        padding: 0 var(--categories-gap-x);
        margin-bottom: var(--categories-gap-y);

        @media screen and (max-width: 1000px) { width: 50%; }
        @media screen and (max-width: 600px) { width: 100%; }
    }

    &__category {
        display: block;
        width: 100%;
        padding-top: 40px;
        padding-right: 45px;
        padding-bottom: 40px;
        padding-left: 45px;
        border-radius: 50px;
        background-color: rgb(var(--rgb-darker-blue));

        text-align: left;

        @media screen and (max-width: 600px) { border-radius: 25px; }
    }

    &__category-title {
        display: block;
        margin-bottom: 15px;

        font-size: var(--fz-26);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__category-false-link {
        pointer-events: none;

        span {
            padding-bottom: 4px;
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-white), 0.4);
            transition: box-shadow 300ms var(--out-cubic);

            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
        }
    }

    &__category:hover &__category-false-link span {
        box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-white));
    }
}