/*
|--------------------------------------------------------------------------
| Les Mixins
|--------------------------------------------------------------------------
|
| Les mixins permettent de définir des styles pouvant être réutiliser
| n'importe où dans dans le SCSS.
*/

@use "sass:math";
@use "functions" as *;
@use "font-sizes" as *;
@use "spacings" as *;
@use "../abstract/variables";


/*
|---------------------------------------------------------------------------
| @mixin fontSizes
|---------------------------------------------------------------------------
|
| Mixin permettant de convertir en variables CSS les font-sizes à l'aide
| de la map $font-sizes étant dans abstract/font-sizes.scss. Ce nombre sera
| converti en rem. Une variable --fz-${name} sera créée dans tous les cas et
| une variable --fz-nus-${name} sera créée si l'entrée utilise les
| propriétés value, min et max. nus signifie no up scaling donc en haut de
| 1440, la font-size restera à sa valeur de base. Ce mixin prend en
| paramètre le ou les noms de variable CSS de la font-size requis pour le
| projet. Format: fontSizes("[name]");
|
| :root {
|     // Exemple avec value, min et max
|     @include fontSizes("70"); // output: --fz-70, --fz-nus-70
|     // Exemple avec base et media query
|     @include fontSizes("17"); // output: --fz-17
| }
|
*/

@mixin fontSizes($font-sizes-needed...) {
    @each $font-size-needed in $font-sizes-needed {
        @each $font-size-property, $font-sizes-map in $font-sizes {
            @if $font-size-property == $font-size-needed {
                @if map-get($font-sizes-map, "base") != null {
                    @each $media-query-property, $media-query-value in $font-sizes-map {
                        $size: math.div($media-query-value, 10);
                        @if $media-query-property == "base" {
                            --fz-#{$font-size-property}: #{$size}rem;
                        } @else {
                            @media #{$media-query-property} {
                                --fz-#{$font-size-property}: #{$size}rem;
                            }
                        }
                    }
                } @else {
                    $minViewport: 500;
                    $optimalViewport: 1440;
                    $maxViewport: 1920;
                    $minViewportRem: math.div($minViewport, 10);
                    $optimalViewportRem: math.div($optimalViewport, 10);
                    $maxViewportRem: math.div($maxViewport, 10);

                    $value: map-get($font-sizes-map, "value");
                    $valueRem: math.div($value, to-number("#{variables.$base-font-size}"));

                    $targetedMinValue: 100%;
                    @if map-get($font-sizes-map, "min") != null {
                        $targetedMinValue: map-get($font-sizes-map, "min");
                    }

                    $targetedMaxValue: 100%;
                    @if map-get($font-sizes-map, "max") != null {
                        $targetedMaxValue: map-get($font-sizes-map, "max");
                    }

                    $minValue: 0;
                    @if str-index("#{$targetedMinValue}", 'px') {
                        $minValue: to-number("#{$targetedMinValue}");
                    } @else if str-index("#{$targetedMinValue}", '%') {
                        $minValue: math.div($value, 100) * to-number("#{$targetedMinValue}");
                    }
                    $minValue: math.div($minValue, to-number("#{variables.$base-font-size}"));

                    $maxValue: 0;
                    @if str-index("#{$targetedMaxValue}", 'px') {
                        $maxValue: to-number("#{$targetedMaxValue}");
                    } @else if str-index("#{$targetedMaxValue}", '%') {
                        $maxValue: math.div($value, 100) * to-number("#{$targetedMaxValue}");
                    }
                    $maxValue: math.div($maxValue, to-number("#{variables.$base-font-size}"));

                    --fz-nus-#{$font-size-property}: #{$valueRem}rem;
                    @media screen and (max-width: #{$optimalViewport}px)  {
                        --fz-#{$font-size-property}: max(#{$minValue}rem + (#{$valueRem} - #{$minValue}) * ((100vw - #{$minViewportRem}rem) / (#{$optimalViewportRem} - #{$minViewportRem})), #{$minValue}rem);
                        --fz-nus-#{$font-size-property}: max(#{$minValue}rem + (#{$valueRem} - #{$minValue}) * ((100vw - #{$minViewportRem}rem) / (#{$optimalViewportRem} - #{$minViewportRem})), #{$minValue}rem);
                    }
                    @media screen and (min-width: #{$optimalViewport + 1}px) {
                        --fz-#{$font-size-property}: min(#{$valueRem}rem + (#{$maxValue} - #{$valueRem}) * ((100vw - #{$optimalViewportRem}rem) / (#{$maxViewportRem} - #{$optimalViewportRem})), #{$maxValue}rem);
                    }
                }
            }
        }
    }
}


/*
|---------------------------------------------------------------------------
| @mixin spacings
|---------------------------------------------------------------------------
|
| Mixin permettant de convertir en variables CSS les espacements à l'aide
| de la map $spacings étant dans abstract/spacings.scss. Une variable
| --${direction}-${name} sera créée et aussi une variable
| --${direction}-nus-${name} sera créée où nus signifie no up scaling donc
| en haut de 1440, la font-size restera à sa valeur de base. Ce mixin prend
| en paramètre le ou les noms de variable CSS de l'espacement requis pour
| le projet. Format: spacings("[direction]-[name]");
|
| :root {
|     @include spacings("x-70"); // output: --x-70, --x-nus-70
| }
|
*/

@mixin spacings($spacings-needed...) {
    $minViewport: 500;
    $optimalViewport: 1440;
    $maxViewport: 1920;

    @each $spacing-needed in $spacings-needed {
        $direction-needed: "Direction";
        $name-needed: str-slice($spacing-needed, 3);
        @if str-index($spacing-needed, "x") == 1 {
            $direction-needed: "x";
        } @else {
            $direction-needed: "y";
        }

        @each $type-of-spacing, $type-of-spacing-map in $spacings {
            @each $spacing-value, $spacing-value-map in $type-of-spacing-map {
                @if $direction-needed == $type-of-spacing and $name-needed == $spacing-value {
                    $value: map-get($spacing-value-map, "value");

                    $targetedMinValue: 100%;
                    @if map-get($spacing-value-map, "min") != null {
                        $targetedMinValue: map-get($spacing-value-map, "min");
                    }

                    $targetedMaxValue: 100%;
                    @if map-get($spacing-value-map, "max") != null {
                        $targetedMaxValue: map-get($spacing-value-map, "max");
                    }

                    $minValue: 0;
                    @if str-index("#{$targetedMinValue}", 'px') {
                        $minValue: to-number("#{$targetedMinValue}");
                    } @else if str-index("#{$targetedMinValue}", '%') {
                        $minValue: math.div($value, 100) * to-number("#{$targetedMinValue}");
                    }

                    $maxValue: 0;
                    @if str-index("#{$targetedMaxValue}", 'px') {
                        $maxValue: to-number("#{$targetedMaxValue}");
                    } @else if str-index("#{$targetedMaxValue}", '%') {
                        $maxValue: math.div($value, 100) * to-number("#{$targetedMaxValue}");
                    }

                    --#{$type-of-spacing}-nus-#{$spacing-value}: #{$value}px;
                    @media screen and (max-width: 1440px)  {
                        --#{$type-of-spacing}-#{$spacing-value}: max(#{$minValue}px + (#{$value} - #{$minValue}) * ((100vw - #{$minViewport}px) / (#{$optimalViewport} - #{$minViewport})), #{$minValue}px);
                        --#{$type-of-spacing}-nus-#{$spacing-value}: max(#{$minValue}px + (#{$value} - #{$minValue}) * ((100vw - #{$minViewport}px) / (#{$optimalViewport} - #{$minViewport})), #{$minValue}px);
                    }
                    @media screen and (min-width: (1441px)) {
                        --#{$type-of-spacing}-#{$spacing-value}: min(#{$value}px + (#{$maxValue} - #{$value}) * ((100vw - #{$optimalViewport}px) / (#{$maxViewport} - #{$optimalViewport})), #{$maxValue}px);
                    }
                }
            }
        }
    }
}
