.c-breadcrumb {
    position: absolute;
    top: calc(var(--header-height) / 2);
    right: 0;
    left: 0;
    translate: 0 -50%;
    transition: top 500ms var(--in-out-cubic);

    @media screen and (max-width: 1024px) { top: calc(var(--header-height) + 20px); }

    &__container {
        width: 100%;
        padding-right: calc(var(--x-40) + var(--header-right-width) + 90px);
        padding-left: calc(var(--x-40) + var(--header-logo-width) + 90px);

        @media screen and (max-width: 1024px) {
            padding-right: var(--x-40);
            padding-left: var(--x-40);
        }
    }

    &__list {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        white-space: nowrap;
        width: 100%;

        color: rgb(var(--rgb-dark-blue));
        line-height: 24px;
    }

    &__link-item,
    &__item {
        font-size: 1.5rem;
        font-weight: 600;
        color: inherit;

        @media screen and (max-width: 400px) { font-size: 1.3rem; }
    }

    &__link-item {
        transition: color 300ms;

        &:hover {
            color: rgba(var(--rgb-dark-blue), 0.6);
        }
    }

    &__space {
        translate: 0 -2px;
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-right: 20px;
        margin-left: 20px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-dark-blue));
    }

    &__link-item:nth-child(2) &__space,
    &__item:nth-child(2) &__space {
        display: none;
    }
}