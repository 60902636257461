@use "../base/typography";

.s-cms-introduction {
    > :first-child {
        margin-top: 0 !important;
    }

    > :last-child {
        margin-bottom: 0 !important;
    }

    p {
        @extend .c-introduction;

        margin-top: var(--y-25);
        margin-bottom: var(--y-25);
    }

    a {
        @extend .c-underlined-link;

        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        color: inherit;

        svg {
            margin-left: 0.3em;
            margin-right: 0.3em;
        }
    }

    strong,
    b {
        font-weight: 700;
    }

    em,
    i {
        font-style: italic;
    }

    sup {
        position: relative;
        top: -0.3em;
        font-size: max(0.7em, 1.2rem);
        vertical-align: baseline;
    }

    sub {
        position: relative;
        top: 0.3em;
        font-size: max(0.7em, 1.2rem);
        vertical-align: baseline;
    }
}
