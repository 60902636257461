.c-checkbox-field {
    --checkbox-field-title-color: rgb(var(--rgb-dark-blue));
    --checkbox-field-label-color: rgb(var(--rgb-dark-blue));
    --checkbox-field-dot-color: rgb(var(--rgb-white));
    --checkbox-field-box-background-color: rgb(var(--rgb-light-blue));
    --checkbox-field-box-background-color-active: rgb(var(--rgb-dark-blue));
    --checkbox-field-box-border-color: rgba(var(--rgb-dark-blue), 0.3);
    --checkbox-field-box-border-color-active: rgb(var(--rgb-dark-blue));

    --input-field-color: rgb(var(--rgb-dark-blue));
    --input-field-placeholder-color: rgba(var(--rgb-dark-blue), 0.8);
    --input-field-background: rgb(var(--rgb-light-blue));
    --input-field-border: rgba(var(--rgb-dark-blue), 0.3);
    --input-field-border-active: rgb(var(--rgb-dark-blue));
    --input-field-border-error: rgb(var(--rgb-red));
}

.c-checkbox-field {
    --checkbox-field-label-font-size: var(--fz-16);
    position: relative;

    &__header {
        margin-bottom: var(--y-nus-30);
    }

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__label {
        display: flex;
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &:last-child {
            margin-bottom: 0;
        }

        &--disabled {
            opacity: 0.6;
            pointer-events: none;
        }
    }

    &__text {
        flex: 1;
        font-family: var(--ff);
        font-size: var(--checkbox-field-label-font-size);
        line-height: 1.5em;
        color: var(--checkbox-field-label-color);
        order: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &--textInput {
            flex: 0;
        }

        span {
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: rgb(var(--rgb-red));
            border-radius: 50%;
            vertical-align: super;
            margin-bottom: 0.2em;
            margin-left: 2px;
        }
    }

    &__text-input {
        order: 3;
        flex: 1;
        margin-left: 10px;
        width: 100%;
        padding-bottom: 8px;
        margin-bottom: 2px;
        font-family: var(--ff);
        font-size: var(--fz-16);
        line-height: 1.5em;
        color: var(--input-field-color);
        border: 0;
        border-bottom: 1px solid var(--input-field-border);
        border-radius: 0; // Nécéssaire même si 0 pour éviter les coins ronds en iOS
        background-color: var(--input-field-background);
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;
        box-shadow: 0 0 0 100px var(--input-field-background) inset; // Choisir la couleur voulu pour le input (Cache le background bleu de l'autofill)


        &:focus {
            border-color: var(--input-field-border-active);
            border-width: 2px;
            margin-bottom: 0px; // Pour éviter le "skip" de 1px lorsque le border passe de 1px à 2px au focus
        }

    }

    &__box {
        position: relative;
        width: 18px;
        height: 18px;
        margin-top: calc(((var(--checkbox-field-label-font-size) * 1.5) - 18px) / 2);
        margin-right: 15px;
        background-color: var(--checkbox-field-box-background-color);
        border: 1px solid var(--checkbox-field-box-border-color);
        border-radius: 3px;
        order: 1;
        transition-property: background-color, border-color;
        transition-duration: 225ms;

        &::before {
            content: "";
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 225ms;
            background: url("../medias/images/icons/checkmark.svg") no-repeat center/contain;
        }
    }

    &__input:checked ~ &__box {
        background-color: var(--checkbox-field-box-background-color-active);
        border-color: var(--checkbox-field-box-border-color-active);

        &::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &--inline &__options {
        margin-top: -20px;
    }

    &--inline &__label {
        display: inline-flex;
        margin: 20px 30px 0 0;
    }

    &__label--textInput {
        &__text {
            flex: 0;
        }
    }
}
