#page-map {
    .mapPin {
        width: 47px;
        height: 60px;
        font-size: 25px;
        line-height: 60px;
        cursor: pointer;
        text-align: center;
        display: block;

        .marker {
            width: 47px;
            height: 60px;
            fill: rgb(var(--rgb-lime));
            stroke: rgb(var(--rgb-lime));
            filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2));
            transition: fill 300ms, stroke 300ms;
        }

        &:hover .marker,
        &.active .marker{
            fill: rgb(var(--rgb-dark-blue));
            stroke: rgb(var(--rgb-dark-blue));
        }

        .icon {
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 1em;
            height: 1em;
            fill: transparent;
            stroke: rgb(var(--rgb-white));
            stroke-width: 5px;
            stroke-linecap: round;
        }
    }
}
