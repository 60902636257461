.c-contact-infos {
    &__container {
        display: flex;

        @media screen and (max-width: 1100px) { flex-direction: column; }
    }

    &__block {
        width: 50%;
        padding-top: 50px;
        padding-right: max(var(--x-45), 30px);
        padding-bottom: 50px;
        padding-left: max(var(--x-45), 30px);
        border-radius: 50px;
        background-color: rgb(var(--rgb-darker-blue));

        @media screen and (max-width: 1100px) { width: 100%; }
        @media screen and (max-width: 600px) { border-radius: 25px; }

        &:first-child {
            margin-right: 30px;

            @media screen and (max-width: 1100px) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }

    &__name {
        margin-bottom: 6px;

        font-size: var(--fz-26);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__job {
        font-size: 1.5rem;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));
    }

    &__list {
        margin-top: 28px;
    }

    &__list-item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        margin-right: 15px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-dark-blue));

        svg {
            width: 18px;
            height: 18px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 1.5px;
        }

        &.--facebook svg {
            stroke: none;
            stroke-width: initial;
            fill: rgb(var(--rgb-white));
        }
    }

    &__phone,
    &__email,
    &__facebook {
        padding-bottom: 4px;

        span {
            padding-bottom: 4px;
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-white), 0.4);
            transition: box-shadow 300ms var(--out-cubic);

            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
        }

        &:hover span {
            box-shadow: inset 0 -0.1em 0 0 rgb(var(--rgb-white));
        }
    }

    &__facebook svg {
        width: 12px;
        height: 12px;
        margin-left: 8px;
        stroke: rgb(var(--rgb-white));
        stroke-width: 2px;
    }
}