@use "../base/typography";

.c-events-component {
    --slide-width: 682px;
    @media screen and (max-width: 1200px) { --slide-width: 582px; }
    @media screen and (max-height: 700px) { --slide-width: 582px; }
    @media screen and (max-width: 1000px) { --slide-width: 482px; }
    @media screen and (max-width: 800px) { --slide-width: 382px; }
    @media screen and (max-width: 600px) { --slide-width: 342px; }
    @media screen and (max-width: 500px) { --slide-width: 310px; }
}

.c-events-component {
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__top-section {
        padding-right: var(--x-80);
        padding-bottom: 40px;
        padding-left: var(--x-80);
    }

    &__top-section-text {
        display: inline-block;
        width: 100%;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);

        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.4em;
        letter-spacing: 0.1em;
        color: rgb(var(--rgb-dark-blue));
        text-transform: uppercase;
        text-align: center;
    }

    &__swiper {
        position: relative;
    }

    &__swiper-navigation {
        position: absolute;
        top: calc(((var(--slide-width) / 100) * 71.1) / 2); // Voir le padding-top du picture du c-event-card pour le 71.1
        left: calc(50% - (var(--slide-width) / 2) - 60px);
        z-index: 5;
        translate: -50% -50%;
        width: 82px;
        height: 50px;
        transition: opacity 300ms, visibility 0ms 0ms;

        @media screen and (max-width: 800px) { left: calc(50% - (var(--slide-width) / 2) - 30px); }
        @media screen and (max-width: 600px) { display: none; }
    }

    &__swiper-navigation-container {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 39px;
        background-color: rgb(var(--rgb-light-blue));
    }

    &__swiper-arrow-prev,
    &__swiper-arrow-next {
        display: flex;
        width: 14px;
        height: 14px;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        svg {
            width: 100%;
            height: 100%;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;
        }
    }

    &__swiper-arrow-prev svg {
        rotate: 180deg;
    }

    .swiper-button-disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    .swiper-button-lock {
        display: block!important;
        opacity: 0.6;
        pointer-events: none;
    }

    .swiper-wrapper {
        --swiper-wrapper-transition-timing-function: var(--in-out-cubic);
        align-items: stretch;
    }

    .swiper-slide {
        width: var(--slide-width);
        height: auto!important;
        margin-left: 60px;

        @media screen and (max-width: 800px) { margin-left: 40px; }
        @media screen and (max-width: 600px) { margin-left: 30px; }
        @media screen and (max-width: 400px) { margin-left: 15px; }

        &:first-child,
        &:last-child {
            @media screen and (max-width: 600px) {
                width: calc(var(--slide-width) + var(--x-80));
            }
        }

        &:first-child {
            margin-left: 60px;
            @media screen and (max-width: 600px) {
                padding-left: var(--x-80);
                margin-left: 0;
            }
        }

        &:last-child {
            @media screen and (max-width: 600px) { padding-right: var(--x-80); }
        }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--white-to-lime;
        @extend .c-button--svg-on-the-right;

        align-self: center;
        margin-top: 60px;

        @media screen and (max-width: 600px) { margin-top: 30px; }
    }
}