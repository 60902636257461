@use "../../base/typography";

.c-buttons-repeater {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;

    // Button
    &__button {
        @extend .c-button;
        @extend .c-button--white-to-lime;
        @extend .c-button--svg-on-the-right;
    }

    &__button-circle-plus,
    &__button-circle-minus {
        display: inline-block;

        &::before,
        &::after {
            content: "";
            width: 16px;
            height: 1.5px;
            background-color: rgb(var(--rgb-dark-blue));
            border-radius: 2px;
            transition: background-color 300ms;
        }

        &::before {
            transform: translate3d(50%, 0, 0);
        }

        &::after {
            transform: translate3d(-50%, 0, 0) rotate(90deg);
        }
    }

    &__button-circle-minus::after {
        opacity: 0;
    }

    &__button:hover &__button-circle-plus,
    &__button:hover &__button-circle-minus {
        &::before,
        &::after {
            background-color: rgb(var(--rgb-white));
        }
    }
}
