@use "../../abstract/placeholders";
@use '../buttons/button-hardcoded';

.c-rubric-card {
    position: relative;
    border-top: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    border-right: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    border-bottom: 1px solid transparent;
    border-left: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    border-top-left-radius: var(--rubric-border-radius);
    border-top-right-radius: var(--rubric-border-radius);
    background-color: rgb(var(--rgb-white));
    transition: background-color 300ms, border-color 0ms 300ms, border-radius 100ms var(--in-out-quart) 300ms;
    -webkit-tap-highlight-color: transparent;

    &::before {
        content: "";
        width: 100%;
        height: calc(var(--rubric-header-height) - 34px);
        position: absolute;
        top: 100%;
        left: 0;
        background-color: rgb(var(--rgb-white));
        transition: background-color 300ms;
    }

    &.js-accordion-opened {
        border-bottom-color: rgba(var(--rgb-dark-blue), 0.3);
        background-color: rgb(var(--rgb-light-blue));
        transition: background-color 300ms, border-color 300ms, border-radius 0ms var(--in-out-quart);
    }

    &.js-accordion-opened::before {
        background-color: rgb(var(--rgb-light-blue));
    }

    &__header {
        display: flex;
        align-items: center;
        position: relative;
        min-height: var(--rubric-header-height);
        padding: 30px max(var(--x-240), 30px);
        cursor: pointer;
    }

    &__plus {
        translate: 0 -3px;
        width: 22px;
        height: 22px;

        &::before,
        &::after {
            height: 2px;
            background-color: rgb(var(--rgb-dark-blue));
            border-radius: 25px;
            opacity: 1;
            transition: opacity 300ms;
        }
    }

    &.js-accordion-opened &__plus {
        &::after {
            opacity: 0;
        }
    }

    &__text {
        flex: 1;
        padding-left: 35px;
    }

    &__surtitle {
        display: block;
        margin-bottom: 3px;
        transition: opacity 300ms;

        font-size: 1.3em;
        font-weight: 600;
        line-height: 1.6em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__header:hover &__surtitle {
        opacity: 0.6;
    }

    &__title {
        transition: opacity 300ms;

        font-size: var(--fz-26);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__header:hover &__title {
        opacity: 0.6;
    }

    &__picture {
        @extend %border-radius;

        overflow: hidden;
        flex-shrink: 0;
        width: 143px;
        height: 90px;
        margin-left: 50px;
        border-radius: 25px;

        @media screen and (max-width: 1024px) { margin-left: 40px; }
        @media screen and (max-width: 768px)  { margin-left: 30px; }
        @media screen and (max-width: 600px) { display: none; }
    }

    &__content {
        overflow: hidden;
        height: 0;
        will-change: height;
    }

    &__transition {
        padding-right: max(var(--x-240), 30px);
        padding-left: max(var(--x-240), 30px);
        opacity: 0;
        transform: translateY(100px);
        transition-property: opacity, transform;
        transition-duration: 700ms;
        transition-timing-function: var(--in-out-quart);
    }

    &.js-accordion-opened &__transition {
        opacity: 1;
        transform: none;
        transition-delay: 100ms;
    }

    &__transition > :first-child {
        margin-top: 0;
    }

    &__map-button {
        @extend .c-button-hardcoded;
        @extend .c-button-hardcoded--white;

        margin-bottom: 30px;
    }

    &__cms-content,
    &__documents,
    &__gallery {
        padding-bottom: 30px;

        &:not(:nth-last-child(2)) {
            padding-bottom: var(--y-60);
        }
    }

    &__share {
        position: relative;
        padding-top: 50px;
        padding-bottom: 35px;

        &::before {
            content: "";
            position: absolute;
            top: 30px;
            right: 0;
            left: 0;
            height: 1px;
            background-color: rgba(var(--rgb-dark-blue), 0.3);
        }
    }

    &__share-button {
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        span {
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 1.6em;
            color: rgb(var(--rgb-dark-blue));
        }
    }

    &:not(.js-accordion-opened) {
        iframe {
            pointer-events: none;
        }
    }

    &__companies + &__share {
        display: none;
    }

    // Page de répertoire
    &__companies {
        --companies-gap-x: 10px;
        --companies-gap-y: 10px;

        padding-bottom: var(--y-135);
    }

    &__companies-list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--companies-gap-x) * 2));
        margin-right: calc(0px - var(--companies-gap-x));
        margin-bottom: calc(0px - var(--companies-gap-y));
        margin-left: calc(0px - var(--companies-gap-x));
    }

    &__companies-list-item {
        width: 50%;
        padding: 0 var(--companies-gap-x);
        margin-bottom: var(--companies-gap-y);

        @media screen and (max-width: 900px) { width: 100%; }
    }

    .c-company-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 62px;
        padding-top: 17px;
        padding-right: 25px;
        padding-bottom: 13px;
        padding-left: 25px;
        border-radius: 20px;
        background-color: rgb(var(--rgb-white));

        span {
            display: inline-block;
            margin-right: 20px;
            transition: opacity 300ms;

            font-size: var(--fz-17);
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-dark-blue));
        }

        &:hover span {
            opacity: 0.6;
        }

        svg {
            flex-shrink: 0;
            width: 18px;
            height: 18px;
            stroke: rgb(var(--rgb-dark-blue));
            stroke-width: 2px;
            transition: opacity 300ms;
        }

        &:hover svg {
            opacity: 0.6;
        }
    }
}
