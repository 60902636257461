.c-button-calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: rgb(var(--rgb-darker-blue));

    svg {
        width: 20px;
        height: 20px;
        stroke: rgb(var(--rgb-white));
        stroke-width: 1.5px;
    }
}