#page-map {
    --map-overlay-locations-width: 380px;
    --map-overlay-location-width: 380px;
    --map-header-block-height: 0px;
    --map-nav-block-height: 0px;

    @media screen and (max-width: 1024px) {
        --map-header-block-height: 95px;
        --map-nav-block-height: 65px;
    }
}
