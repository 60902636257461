.c-button-events {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(var(--rgb-white));
    transition: background-color 300ms;

    &:hover {
        background-color: rgb(var(--rgb-dark-blue));
    }

    svg {
        width: 18px;
        height: 18px;
        stroke: rgb(var(--rgb-dark-blue));
        stroke-width: 2.5px;
        transition: stroke 300ms;
    }

    &:hover svg {
        stroke: rgb(var(--rgb-white));
    }
}