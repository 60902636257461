@use "../base/typography";
@use '../abstract/placeholders';

.c-showcase {
    &__container {
        display: flex;
        justify-content: space-between;
        background-color: rgb(var(--rgb-light-blue));
        border-radius: 100px;

        @media screen and (max-width: 900px) {
            flex-direction: column-reverse;
            border-radius: 75px;
        }
        @media screen and (max-width: 600px) { border-radius: 40px; }
    }

    &__text-block {
        align-self: center;
        padding-top: var(--y-80);
        padding-right: var(--x-120);
        padding-bottom: var(--y-80);
        padding-left: var(--x-120);

        &.--with-image {
            padding-right: var(--x-60);
        }

        @media screen and (max-width: 900px) { align-self: initial; }
    }

    &__surtitle {
        display: inline-block;
        margin-bottom: 10px;

        font-size: 1.3rem;
        font-weight: 600;
        line-height: 1.6em;
        color: rgb(var(--rgb-dark-blue));
    }

    &__title {
        font-size: var(--fz-30);
        font-weight: 500;
        line-height: 1.3em;
    }

    &__button {
        @extend .c-button;
        @extend .c-button--white-to-lime;
        @extend .c-button--svg-on-the-right;

        margin-top: 40px;
        pointer-events: none;

        @media screen and (max-width: 600px) { margin-top: 20px; }
    }

    &:hover &__button {
        --button-color: rgb(var(--rgb-white));

        &::after {
            translate: 0;
        }

        span:last-child {
            --button-circle-background-color: transparent;
            --button-circle-border-color: rgb(var(--rgb-white));
        }

        svg {
            --button-svg-color: rgb(var(--rgb-white));
        }
    }

    &__picture-container {
        @extend %border-radius;

        overflow: hidden;
        align-self: flex-end;
        flex-shrink: 0;
        width: 520px;
        border-radius: 100px;

        @media screen and (max-width: 1200px) { width: 420px; }
        @media screen and (max-width: 900px) {
            align-self: initial;
            width: 100%;
            border-top-left-radius: 75px;
            border-top-right-radius: 75px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        @media screen and (max-width: 600px) {
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
        }
    }

    &__picture {
        position: relative;
        width: 100%;
        padding-top: 83%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}