/*
|--------------------------------------------------------------------------
| Les variables SCSS 
|--------------------------------------------------------------------------
|
| Les variables SCSS pouvant être utilisé partout dans le projet.
|
*/

$base-font-size: 10px;
