@use "../../../base/typography";

.overlay-locations {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: var(--map-overlay-locations-width);
    background: rgb(var(--rgb-white));
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
    overflow: auto;
    z-index: 10;

    @media screen and (max-width: 1024px) {
        top: calc(var(--map-header-block-height) + var(--map-nav-block-height));
        height: calc(100% - calc(var(--map-header-block-height) + var(--map-nav-block-height)));
        opacity: 0;
        visibility: hidden;
        transform: translateY(50px);
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        max-width: 100%;
    }

    &__logo-container {
        display: block;
        width: fit-content;
        padding: 35px 40px 0;
        margin-bottom: var(--y-70);

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    &__logo-image {
        width: 188px;
        height: 64px;
        vertical-align: bottom;
    }

    &__links-level-1 {
        padding: 0 40px var(--y-70);

        @media screen and (max-width: 500px) {
            padding: 10px var(--x-40) var(--y-70);
        }
    }


    // Links level 1
    &__links-level-1-item {
        padding: 16px 0;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__link-level-1 {
        position: relative;
        display: block;
    }

    &__link-level-1-text {
        display: block;
        padding: 14px 32px 14px 0;
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.25em;
        color: rgb(var(--rgb-dark-blue));
        cursor: pointer;
        transition: color 300ms, opacity 300ms;
    }

    &__links-level-1-item:hover &__link-level-1-text,
    &__links-level-1-item.active &__link-level-1-text {
        color: rgb(var(--rgb-lime));
    }

    &__link-level-1-svg-container {
        display: block;
        position: absolute;
        top: 50%;
        right: 3px;
        transform: translateY(-50%) rotate(90deg);
        width: 7px;
        height: 11px;
        transition: transform 300ms;
    }

    &__links-level-1-item.active &__link-level-1-svg-container {
        transform: translateY(-50%) rotate(270deg);
    }

    &__link-level-1-svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        stroke: rgb(var(--rgb-lime));
        transition: stroke 300ms;
    }


    // Liste level 2
    &__links-level-2 {
        display: none;
        opacity: 0;
        overflow: hidden;
        transform: translateY(30px);
        transition: opacity 300ms, transform 300ms;

        &.open {
            opacity: 1;
            transform: none;
        }
    }


    // Links level 2
    &__links-level-2-item {
        width: fit-content;
        margin-top: 25px;

        &:first-child { margin-top: 20px; }
        &:last-child { margin-bottom: 15px; }
    }

    &__link-level-2 {
        position: relative;
    }

    &__link-level-2-text {
        display: block;
        padding-left: 40px;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-dark-blue));
        cursor: pointer;
        transition: color 300ms;
    }

    &__link-level-favorite-text {
        cursor: default;
    }

    &__links-level-2-item:hover &__link-level-2-text,
    &__links-level-2-item.active &__link-level-2-text {
        color: rgb(var(--rgb-lime));
    }

    &__link-level-2-svg-container {
        position: absolute;
        top: 13px;
        left: 0;
        transform: translateY(-50%);
        display: flex;
    }

    &__link-level-2-svg {
        width: 22px;
        height: 22px;
        fill: none;
        stroke: rgb(var(--rgb-dark-blue));
        stroke-width: 8px;
        transition: stroke 300ms;
        z-index: 1;
    }

    &__links-level-2-item:hover &__link-level-2-svg,
    &__links-level-2-item.active &__link-level-2-svg {
        stroke: rgb(var(--rgb-lime));
    }


    // Liste level 3
    &__links-level-3 {
        display: none;
        padding-left: 40px;
        opacity: 0;
        overflow: hidden;
        transform: translateY(30px);
        transition: opacity 400ms, transform 400ms;

        &.open {
            opacity: 1;
            transform: none;
        }
    }


    // Links level 3
    &__links-level-3-item {
        position: relative;
        width: fit-content;
        margin-top: 5px;

        &:first-child { margin-top: 10px; }
        &:last-child { margin-bottom: 5px; }
    }

    &__link-level-3 {
        display: inline-block;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-blue));
        padding: 6px 0;
        cursor: pointer;
        transition: color 300ms;
        z-index: 1;
    }

    &__links-level-3-item:hover &__link-level-3,
    &__links-level-3-item.active &__link-level-3 {
        color: rgb(var(--rgb-lime));
    }

    &__button {
        @extend .c-button;
        @extend .c-button--dark-blue-to-lime;
        @extend .c-button--svg-on-the-left;

        align-self: center;
        margin-top: auto;
        margin-bottom: 30px;
    }

}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.showOverlayLocations {
     .overlay-locations {
        opacity: 1;
        visibility: visible;
        transform: none;
        transition: opacity 300ms var(--in-out-cubic), transform 300ms var(--in-out-cubic);
    }

    #page-map .nav-block-mobile__button-svg {
        transform: translate(-50%, -50%) rotate(270deg);
    }

    #page-map .nav-block-mobile__button-text {
        color: rgb(var(--rgb-lime));
    }
}

/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.showOverlayLocations.closingLocations {
    .overlay-locations {
        opacity: 0;
        transform: translateY(50px);
    }

    #page-map .nav-block-mobile__button-svg {
        transform: translate(-50%, -50%) rotate(90deg);
    }

    #page-map .nav-block-mobile__button-text {
        color: rgb(var(--rgb-dark-blue));
    }
}
