.c-form-label {
    display: block;
    margin-bottom: 12px;
    font-size: var(--fz-17);
    font-weight: 600;
    line-height: 1.1em;
    color: rgb(var(--rgb-dark-blue));

    &--radio-and-checkbox {
        display: block;
        margin-bottom: 5px;
        font-family: var(--ff);
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-blue));
    }

    span {
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: rgb(var(--rgb-red));
        border-radius: 50%;
        vertical-align: super;
        margin-bottom: 0.2em;
        margin-left: 2px;
    }
}

.c-form-required-dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: rgb(var(--rgb-red));
    border-radius: 50%;
    vertical-align: super;
    margin-bottom: 0.2em;
    margin-left: 2px;
}
