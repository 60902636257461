/*
|--------------------------------------------------------------------------
| Root
|--------------------------------------------------------------------------
|
| Déclaration de tout ce qui doit se retrouver dans le sélecteur :root.
|
*/

@use "../abstract/mixins" as *;

:root {
    // Générer les variables CSS des font-sizes
    @include fontSizes(
        "14", "16", "17", "18", "20", "21", "22", "24", "25", "26", "28", "30", "35", "40", "45", "50", "55", "60", "65", "70", "80", "100",
    );

    // Générer les variables CSS des espacements
    @include spacings(
        "x-40", "x-45", "x-50", "x-55", "x-60", "x-65", "x-70", "x-80", "x-90", "x-100", "x-110", "x-120", "x-130", "x-160", "x-200", "x-240",
        "y-25", "y-30", "y-35", "y-40", "y-45", "y-50", "y-55", "y-60", "y-65", "y-70", "y-75", "y-80", "y-90", "y-100", "y-110", "y-120", "y-135", "y-160",
    );


    /*
    |--------------------------------------------------------------------------
    | VARIABLES CSS
    |--------------------------------------------------------------------------
    */

    // Variables de texte
    --paragraph-line-height: 1.6em;

    // Variables du header
    --header-height: 140px;
    @media screen and (max-height: 700px) { --header-height: 110px; }
    @media screen and (max-width: 600px) { --header-height: 95px; }

    --header-logo-width: 260px;
    --header-right-width: 170px;

    // Variables de l'alerte
    --alert-height: 0px;

    // Variable du tooltip (i dans un cercle)
    --tooltip-dimensions: 18px;

    // Rubriques
    --rubric-header-height: 150px;
    @media screen and (max-width: 1024px) { --rubric-header-height: 120px; }
    @media screen and (max-width: 768px)  { --rubric-header-height: 100px; }

    --rubric-border-radius: 100px;
    @media screen and (max-width: 800px) { --rubric-border-radius: 75px; }
    @media screen and (max-width: 600px) { --rubric-border-radius: 60px; }

    // Variables du bouton de soumission d'activité
    --activity-button-height: 75px;

    // Variables de l'entête de page
    --page-title-padding-top: calc(var(--header-height) + var(--alert-height) + 40px);
    @media screen and (max-height: 700px) { --page-title-padding-top: calc(var(--header-height) + var(--alert-height)); }
    @media screen and (max-width: 1024px) { --page-title-padding-top: calc(var(--header-height) + var(--alert-height) + 40px); }

    --page-title-bottom-section-height: 10.3vw;
    @media screen and (max-width: 400px) { --page-title-bottom-section-height: 50px; }

    // Variables des selects de la page de répertoire
    --selects-height: 65px;
    @media screen and (max-width: 700px) { --selects-height: 150px; }
}
