.overlay-location {
    --map-overlay-location-bande-height: 80px;
    --map-overlay-location-image-height: 175px;

    @media screen and (max-width: 350px) {
        --map-overlay-location-image-height: 150px;
    }
}

.overlay-location {
    position: absolute;
    left: var(--map-overlay-locations-width);
    bottom: -50px;
    width: 100%;
    max-width: var(--map-overlay-location-width);
    height: 100%;
    max-height: calc(100% - 100px);
    background: rgb(var(--rgb-white));
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 1024px) {
        max-height: calc(100% - calc(var(--map-header-block-height) + var(--map-nav-block-height)));
        left: 0;
    }

    @media screen and (max-width: 500px) {
        width: calc(100% - 40px);
    }


    // Bande
    &__bande {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: var(--map-overlay-location-bande-height);
        background-color: rgb(var(--rgb-dark-blue));
        padding: 0 22px;
    }

    &__buttons-round {
        display: flex;
    }

    &__button-round {
        position: relative;
        width: 40px;
        height: 40px;
        background-color: rgb(var(--rgb-white));
        border-radius: 50%;
        transition: background-color 300ms;
    }

    &__button-round:not(:last-child) {
        margin-right: 12px;
    }

    &__button-round:hover {
        background-color: rgb(var(--rgb-lime));
    }

    &__button-round-svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 14px;
        height: 14px;
        stroke: rgb(var(--rgb-dark-blue));
        stroke-width: 4px;
        transition: stroke 300ms;
    }

    &__button-round:hover &__button-round-svg {
        stroke: rgb(var(--rgb-white));
    }


    // Content
    &__content {
        height: calc(100% - var(--map-overlay-location-bande-height));
        padding: var(--y-40) var(--x-40);
        overflow-y: auto;
        overflow-x: hidden;

        p {
            word-break: break-all;
        }

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: rgb(var(--rgb-6));
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(var(--rgb-3));
        }
    }


    // Content top
    &__content-top {
        margin-bottom: 23px;
    }

    &__title {
        display: block;
        font-size: 2.1rem;
        font-weight: 500;
        line-height: 1.3333em; // 40/30
        color: rgb(var(--rgb-dark-blue));
    }

    &__image {
        overflow: hidden;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        height: var(--map-overlay-location-image-height);
        margin-top: 20px;
    }


    // Content bottom - introduction
    &__content-introduction:not(:last-child) {
        margin-bottom: 20px;
    }


    // Content bottom - adresse
    &__content-adresse:not(:last-child) {
        margin-bottom: 20px;
    }


    // Content bottom - infos list
    &__content-infos-list {
        margin-top: 36px;
    }

    &__content-infos-list:not(:last-child) {
        margin-bottom: 36px;
    }

    &__infos-list-item {
        padding: 16px 0;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__infos-list-item:first-child {
        padding-top: 0;
    }

    &__info {
        position: relative;
        padding-left: 25px;
    }

    &__info-svg {
        position: absolute;
        top: 11px;
        left: 0;
        transform: translateY(-50%);
        stroke: rgb(var(--rgb-dark-blue));
    }

    &__info--google-map &__info-svg {
        width: 12px;
        height: 16px;
    }

    &__info--phone &__info-svg {
        width: 17px;
        height: 17px;
    }

    &__info--email &__info-svg {
        width: 15px;
        height: 12px;
    }

    &__info--website &__info-svg {
        width: 15px;
        height: 15px;
    }

    &__info-link {
        font-size: 1.5rem;
        line-height: 1.4667em; // 22/15
        font-weight: 500;
        color: inherit;
        transition: color 300ms;
    }

    &__info-link:hover {
        color: rgb(var(--rgb-lime));
    }


    // Content bottom - horaire
    &__content-horaire:not(:last-child) {
        margin-bottom: 20px;
    }


    // Content bottom - description
    &__content-description:not(:last-child) {
        margin-bottom: 20px;
    }


    // Content bottom - publications
    &__content-events-infos-list {
        margin-top: 40px;
    }

    &__events-infos-list {
        margin-top: 16px;
    }

    &__events-infos-list-item {
        padding: 16px 0;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__events-infos-list-item:first-child {
        padding-top: 0;
    }

    &__event-info {
        position: relative;
        padding-left: 30px;
    }

    &__event-info-svg {
        position: absolute;
        top: 10px;
        left: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        stroke: rgb(var(--rgb-dark-blue));
    }

    &__event-info-link,
    &__event-info-date {
        font-size: 1.5rem;
        line-height: 1.4667em; // 22/15
        color: inherit;
        transition: color 300ms;
    }

    &__event-info-link {
        display: block;
        font-weight: 500;
    }

    &__event-info-link:hover {
        color: rgb(var(--rgb-lime));
    }


    // Content bottom - publications
    &__content-publications-infos-list {
        margin-top: 40px;
    }

    &__publications-infos-list {
        margin-top: 16px;
    }

    &__publications-infos-list-item {
        padding: 16px 0;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__publications-infos-list-item:first-child {
        padding-top: 0;
    }

    &__publication-info {
        position: relative;
        padding-left: 30px;
    }

    &__publication-info-svg {
        position: absolute;
        top: 10px;
        left: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        stroke: rgb(var(--rgb-dark-blue));
    }

    &__publication-info-link {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.4667em; // 22/15
        color: inherit;
        transition: color 300ms;
    }

    &__publication-info-link:hover {
        color: rgb(var(--rgb-lime));
    }


    // Content bottom - others (pages, rubrics)
    &__content-others-infos-list {
        margin-top: 40px;
    }

    &__others-infos-list {
        margin-top: 16px;
    }

    &__others-infos-list-item {
        padding: 16px 0;
        border-bottom: 1px solid rgba(var(--rgb-dark-blue), 0.3);
    }

    &__others-infos-list-item:first-child {
        padding-top: 0;
    }

    &__other-info {
        position: relative;
        padding-left: 30px;
    }

    &__other-info-svg {
        position: absolute;
        top: 11px;
        left: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        stroke: rgb(var(--rgb-dark-blue));
    }

    &__other-info-link {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.4667em; // 22/15
        color: inherit;
        transition: color 300ms;
    }

    &__other-info-link:hover {
        color: rgb(var(--rgb-lime));
    }


    // General - text & subtitle
    &__text,
    &__subtitle {
        font-size: 1.5rem;
        line-height: 1.6667em; // 25/15
    }

    &__subtitle {
        font-weight: 600;
    }


    // General - dynamique
    .s-cms-content {
        --paragraph-font-size: 1.5rem;
        --paragraph-line-height: 1.6667em; // 25/15
        color: inherit;

        p {
            margin-top: var(--y-20);
            margin-bottom: var(--y-20);
        }

        ol,
        ul {
            margin: 15px 0;
        }

        ol > li,
        ul > li {
            --ordered-list-padding: 25px;
            padding-left: 0;
        }

        ol li:not(:first-child),
        .ordered-list li:not(:first-child),
        ul li:not(:first-child),
        .unordered-list li:not(:first-child) {
            margin-top: 2px;
        }

        ol li ol,
        .ordered-list li ol,
        ul li ol,
        .unordered-list li ol,
        ol li ul,
        .ordered-list li ul,
        ul li ul,
        .unordered-list li ul {
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.showOverlayLocation {
    .overlay-location {
        opacity: 1;
        bottom: 0;
        visibility: visible;
        transition: opacity 300ms var(--in-out-cubic), bottom 300ms var(--in-out-cubic);
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.showOverlayLocation.closingLocation {
    .overlay-location {
        opacity: 0;
        bottom: -50px;
    }
}
