@use "sass:math";

.map-block {
    $base-line-height: 24px;
    $background: #0E2D52;
    $off-background: rgba($background, 0.4);
    $spin-duration: 1000ms;
    $pulse-duration: 750ms;

    &__google-map-loading {
        position: absolute;
        right: 0;
        width: calc(100% - var(--map-overlay-locations-width));
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        z-index: 9;

        @media screen and (max-width: 1024px) {
            width: 100%
        }
    }

    &__google-map-loading-pulse {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: math.div($base-line-height, 4);
        height: $base-line-height;
        background: $off-background;
        animation: pulse $pulse-duration infinite;
        animation-delay: ($pulse-duration, 3);

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            display: block;
            height: math.div($base-line-height, 1.5);
            width: math.div($base-line-height, 4);
            background: $off-background;
            transform: translateY(-50%);
            animation: pulse $pulse-duration infinite;
        }

        &:before {
            left: math.div($base-line-height, -2);
        }

        &:after {
            left: math.div($base-line-height, 2);
            animation-delay: math.div($pulse-duration, 1.5);
        }
    }
}

@keyframes pulse {
    50% {
        background: #0E2D52;
    }
}
