// Boutons hardcodés
@use "../../abstract/placeholders";

.c-button-hardcoded {
    --stroke-width: 1.5px;
}

.c-button-hardcoded {
    @extend %border-radius;

    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    width: fit-content;
    padding: 11px 24px;
    border-radius: 46px;
    transition: background-color 300ms;
    isolation: isolate;

    &::before,
    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        border-radius: 46px;
    }

    &::before {
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        border: 1px solid var(--button-border-color);
        background-color: var(--button-background-color);
    }

    &::after {
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        background-color: var(--button-background-color-hover);
        translate: 0 100%;
        transition: translate 300ms var(--out-cubic);
    }

    &:hover::after {
        translate: 0;
    }

    svg {
        margin-right: 10px;
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        stroke: var(--button-color);
        stroke-width: var(--stroke-width);
        transition: stroke 300ms;
    }

    &:hover svg {
        stroke: var(--button-color-hover);
    }

    span {
        margin-bottom: -3px;
        transition: color 300ms;

        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.8em;
        color: var(--button-color);
        text-align: left;
    }

    &:hover span {
        color: var(--button-color-hover);
    }

    &--white {
        --button-border-color: rgba(var(--rgb-dark-blue), 0.3);
        --button-background-color: rgb(var(--rgb-white));
        --button-background-color-hover: rgb(var(--rgb-dark-blue));
        --button-color: rgb(var(--rgb-dark-blue));
        --button-color-hover: rgb(var(--rgb-white));
    }

    &--light-blue {
        --button-border-color: rgb(var(--rgb-light-blue));
        --button-background-color: rgb(var(--rgb-light-blue));
        --button-background-color-hover: rgb(var(--rgb-dark-blue));
        --button-color: rgb(var(--rgb-dark-blue));
        --button-color-hover: rgb(var(--rgb-white));
    }

    &--transparent {
        --button-border-color: rgb(var(--rgb-white));
        --button-background-color: transparent;
        --button-background-color-hover: rgb(var(--rgb-white));
        --button-color: rgb(var(--rgb-white));
        --button-color-hover: rgb(var(--rgb-dark-blue));
    }
}